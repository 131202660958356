import React, { useEffect, useState } from 'react';
import { TextField, Button, Stack, Typography, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { useUserContext } from '../../../auth/AuthProvider';
import { LoadingButton } from '@mui/lab';
const baseUrl = process.env.REACT_APP_BASE_URL;
const EditDoiGenerate = ({generateDoi, doi, handleChange, hasDoi, editable = true }) => {

    const [isGeneratingDoi, setIsGeneratingDoi] = useState(false);
    const [valid, setValid] = useState(true);
    const { token } = useUserContext()
    // Handle the DOI input field change
    const onDoiChange = (event) => {
        const value = event.target.value;
        handleChange(event); // Propagate the value up to the parent
    };
    const getAuthHeaders = () => {
        return token ? { Authorization: `Bearer ${token}` } : {};
    };
    useEffect(() => {
        if (isGeneratingDoi) {
            handleChange({
                target: {
                    name: "generateDoi",
                    value: isGeneratingDoi
                }
            });

        } else {
            handleChange({
                target: {
                    name: "generateDoi",
                    value: isGeneratingDoi
                }
            });
            handleChange({
                target: {
                    name: "doi",
                    value: ""
                }
            });
        }
    }, [isGeneratingDoi])

    const handleGenerateDoi = async () => {
        try {
            // DOI oluşturuluyor, state'i true yapıyoruz

            // handleChange ile generateDoi state'ini true yapıyoruz


            // API'den yeni DOI'yi alıyoruz
            const newDoi = await axios.get(`${baseUrl}/api/v1/generate-doi/next-doi`, {
                headers: getAuthHeaders()
            });

            // Yeni DOI'yi handleChange ile yukarıya gönderiyoruz
            await handleChange({
                target: {
                    name: "doi",
                    value: newDoi.data
                }
            });
            setIsGeneratingDoi(true);
            // DOI oluşturulma işlemi tamamlandığında, state'i false yapıyoruz


        } catch (error) {
            console.error("DOI oluşturulurken bir hata oluştu:", error);
            setIsGeneratingDoi(false);  // Hata durumunda state'i resetliyoruz
        }
    };



    return (
        <Stack spacing={3} >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Assign a doi
            </Typography>

            <FormControlLabel
                disabled={generateDoi}
                control={
                    <Switch
                        checked={hasDoi}
                        onChange={() => {

                            handleChange({
                                target: {
                                    name: "hasDoi",
                                    value: !hasDoi
                                }
                            })
                            setIsGeneratingDoi(false)
                        }}
                        color="primary"
                    />
                }
                label="My preprint has a doi"
            />

            {hasDoi ? (
                <TextField
                    
                    disabled={!valid || !editable}
                    size="small"
                    fullWidth
                    label="DOI"
                    name="doi"
                    value={doi}
                    onChange={onDoiChange}
                    inputProps={{
                        maxLength: 100,
                        pattern: "^10\\.\\d{4,9}/[-._;()/:A-Z0-9]+$"
                    }}
                    helperText="Valid DOI format: 10.XXXX/XXXX"
                />
            ) : (
                <Stack spacing={2}>
                    <Typography>
                        The Raw Data Library assigns a DOI to each uploaded preprint article (if not already assigned by a journal). Assigning a DOI provides more citation opportunities and serves as a milestone for your preprints.
                    </Typography>
                    <TextField
                        disabled={true}
                        size="small"
                        fullWidth
                        name="doi"
                        value={doi}
                        inputProps={{
                            maxLength: 100,
                            pattern: "^10\\.\\d{4,9}/[-._;()/:A-Z0-9]+$"
                        }}
                    />
                    <LoadingButton disabled={generateDoi} variant="contained" color="primary" onClick={handleGenerateDoi}>
                        Generate DOI
                    </LoadingButton>
                </Stack>
            )}
        </Stack>
    );
};

export default EditDoiGenerate;
