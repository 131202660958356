import React, { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  Pagination,
  Stack,
} from '@mui/material';
import {
  Article as ArticleIcon,
  School as SchoolIcon,
  Book as BookIcon,
  Assignment as AssignmentIcon,
  Science,
  BusinessCenter as BusinessCenterIcon,
  MenuBook,
  Email,
  ErrorOutline,
  EditOutlined,
  SchemaOutlined,
} from '@mui/icons-material';
import axios from 'axios';
import { useUserContext } from '../../auth/AuthProvider';
import { Link, useNavigate, useParams } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASE_URL;

const publications = [
  { id: 0, value: 'Article', icon: <ArticleIcon />, endpoint: '/user-articles' },
  { id: 1, value: 'Thesis', icon: <SchoolIcon />, endpoint: '/user-thesis' },
  { id: 2, value: 'Chapter in a book', icon: <BookIcon />, endpoint: '/user-chapter-in-a-book' },
  { id: 3, value: 'Conference paper', icon: <AssignmentIcon />, endpoint: '/user-conference-paper' },
  { id: 4, value: 'Research project', icon: <Science />, endpoint: '/user-research-project' },
  { id: 5, value: 'Company test report', icon: <BusinessCenterIcon />, endpoint: '/user-company-test-report' },
  { id: 6, value: 'Book', icon: <MenuBook />, endpoint: '/user-book' },
  { id: 7, value: 'Letter', icon: <Email />, endpoint: '/user-letter' },
  { id: 8, value: 'Retracted article', icon: <ErrorOutline />, endpoint: '/user-retracted-article' },
  { id: 9, value: 'Editorial Material', icon: <EditOutlined />, endpoint: '/user-editorial-material' },
  { id: 10, value: 'Preprint', icon: <SchemaOutlined />, endpoint: '/user-preprint' },
];

const PublicationList = () => {
  const [publicationsData, setPublicationsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSize, setSelectedSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { type } = useParams();
  const { token, user } = useUserContext();
  const navigate = useNavigate();

  const sizeOptions = [10, 20, 30, 50];

  // Fetch publications data
  const fetchPublications = async (type, size, page) => {
    const selectedPublication = publications.find((pub) => pub.value === (type || 'Article'));
    if (!selectedPublication) return;

    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/v1/publication${selectedPublication.endpoint}`, {
        params: { userId: user?.id, page: page - 1, size },
        headers: { Authorization: `Bearer ${token}` },
      });
      const { publications = [], totalPages = 0 } = response.data;

      if (Array.isArray(publications)) {
        setPublicationsData(publications);
      } else {
        console.error('Invalid data format:', publications);
        setPublicationsData([]);
      }
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching publications:', error);
      setPublicationsData([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on load or when dependencies change
  useEffect(() => {
    fetchPublications(type || "Article", selectedSize, currentPage);
  }, [type, selectedSize, currentPage]);

  // Extract year from a date string
  const getYearFromDate = (dateString) => {
    if (!dateString) return '(continue)';
    const year = new Date(dateString).getFullYear();
    return isNaN(year) ? '(continue)' : year;
  };

  return (

    <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {publicationsData.length > 0 ? (
            <List>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                <Select
                  size="small"
                  value={selectedSize}
                  onChange={(e) => {
                    setSelectedSize(e.target.value);
                    setCurrentPage(1);
                  }}
                  sx={{ minWidth: 120 }}
                >
                  {sizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                      {`${size} per page`}
                    </MenuItem>
                  ))}
                </Select>
                <Typography>
                  {publicationsData.length} publications found.
                </Typography>
              </Stack>
              {publicationsData.map((pub) => (
                <ListItem
                  key={pub.id}
                  sx={{
                    borderBottom: '1px solid #e0e0e0',
                    padding: '16px 24px',
                  }}
                >
                  <ListItemIcon>
                    {publications.find((p) => p.value === pub.type)?.icon || <ArticleIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link
                        to={`/publication/${pub.slug}`}
                        style={{
                          color: "#091582",
                          fontWeight: 'bold',
                          textDecoration: 'none',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                      >
                        {pub.title || 'Untitled'}
                      </Link>
                    }
                    secondary={`${pub.summary || 'No summary available'} (${getYearFromDate(pub.date)})`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>No publications found.</Typography>
          )}
          {totalPages > 1 && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

export default PublicationList;
