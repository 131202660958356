import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useUserContext } from '../../auth/AuthProvider';

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function MyPublicationSelect({ selectedPublication, setSelectedPublication }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { token } = useUserContext();

    // Function to fetch publications from the backend
    const fetchPublications = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/v1/publication/get-my-publications`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const publications = response.data; // Assuming the response returns a list of [id, header] arrays
            setOptions(publications.map(pub => ({
                id: pub[0], // Extract the ID (assuming it's in the first element)
                header: pub[1], // Extract the header (assuming it's in the second element)
            })));
        } catch (error) {
            console.error('Error fetching publications:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = () => {
        setOpen(true);
        fetchPublications(); // Fetch publications when the dropdown opens
    };

    const handleClose = () => {
       
        setOpen(false);
        setOptions([]); // Clear options when the dropdown closes
    };

    const handleSelectionChange = (event, newValue) => {
        // Ensure newValue is not null or undefined before updating
        setSelectedPublication(newValue || { header: "" });
    };

    return (
        <div>
            <Autocomplete
                
                size="small"
                sx={{ mt: 1 }}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.header}
                options={options}
                loading={loading}
                onChange={handleSelectionChange} // Track selection change
                value={{ header: selectedPublication }} // Ensure selectedPublication is not null
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="My publications"
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            },
                        }}
                    />
                )}
            />
        </div>
    );
}
