import React, { useState } from 'react';
import { Paper, Stack, Typography, Grid, IconButton, Button } from '@mui/material';
import { ArrowBack, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const PublicationInfo = ({ publication, comment, references, setStep, edit = false, handleEdit }) => {

    const [showFullText, setShowFullText] = useState(false);
    const maxPreviewLength = 200; // Adjust the maximum length as needed

    const handleToggle = () => {
        setShowFullText((prev) => !prev);
    };

    function capitalizeWords(str) {
        if (!str) {
            return ""
        }
        return str
            .toLowerCase() // Tüm harfleri önce küçük yap
            .split(' ') // Stringi boşluklardan ayırarak bir diziye dönüştür
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Her kelimenin ilk harfini büyük yap
            .join(' '); // Diziyi tekrar birleştir
    }
    return (
        <Paper>
            <Stack spacing={2} sx={{ p: 2 }}>

                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" color="primary.main"><b>Publication Info</b></Typography>
                    <IconButton onClick={edit ? () => handleEdit("PUBLICATION_EDIT") : () => setStep(1)}>
                        <Edit />
                    </IconButton>
                </Stack>
                <Grid container>
                    {Object.keys(publication.data).map((key, index) => {
                        const formattedKey = key
                            .replace(/([a-z])([A-Z])/g, '$1 $2')  // Split CamelCase
                            .toLowerCase()                        // Convert to lowercase
                            .replace(/^\w/, c => c.toUpperCase()); // Capitalize first letter


                        if (key == "generateDoi" || key == "allowComment" || key == "hasDoi" || key == "haveArticle" || key == "slug") {
                            return null;
                        }


                        if (key == "degree") {
                            return (
                                <Grid item md={6} sm={12} key={index}>
                                    <Stack>
                                        <Typography color="primary.main"><b> Degree:</b></Typography>
                                        <Typography>{` ${capitalizeWords(publication.data[key])}`}</Typography>


                                    </Stack>
                                </Grid>
                            );

                        }
                        if (key !== "comment" && key !== "references" && key !== "commentType") {
                            if (key == "doi" || key == "isbn") {
                                return (
                                    <Grid item md={6} sm={12} key={index}>
                                        <Stack>
                                            <Typography color="primary.main"><b>{formattedKey?.toUpperCase()}:</b></Typography>
                                            <Typography>{` ${publication.data[key]}`}</Typography>


                                        </Stack>
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid item md={6} sm={12} key={index}>
                                        <Stack>
                                            <Typography color="primary.main"><b>{formattedKey}:</b></Typography>
                                            {formattedKey.startsWith("Link") ? (
                                                <a target='_blank' href={publication.data[key]}><Typography>{` ${publication.data[key]?.slice(0, 50)}...`}</Typography></a>
                                            ) : (
                                                <Typography>{` ${publication.data[key]}`}</Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                );
                            }

                        }
                        return null;
                    })}
                    {publication.data.commentType && < Grid item md={12} sm={12} mt={2}>
                        <Stack>
                            <Typography color="primary.main"><b>Document Type</b></Typography>
                            <Typography>{publication.data.commentType}</Typography>
                        </Stack>
                    </Grid>}
                    <Grid item md={12} sm={12} mt={2}>
                        <Stack>
                            <Typography color="primary.main"><b>{publication.data.commentType ? publication.data.commentType : "Abstract"}</b></Typography>
                            <Typography>{comment}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={12} sm={12} mt={2}>
                        {references && references.length > 0 && (
                            <Stack>
                                <Typography color="primary.main"><b>References</b></Typography>
                                <Typography style={{ whiteSpace: 'pre-line' }}>
                                    {showFullText
                                        ? references
                                        : references.length > maxPreviewLength
                                            ? `${references.slice(0, maxPreviewLength)}...`
                                            : references}
                                </Typography>
                                {references.length > maxPreviewLength && (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={handleToggle}
                                        size="small"
                                        sx={{ mt: 1 }}
                                    >
                                        {showFullText ? 'Show Less References' : 'Show More References'}
                                    </Button>
                                )}
                            </Stack>
                        )}

                    </Grid>
                </Grid>
            </Stack>
        </Paper >
    );
};

export default PublicationInfo;
