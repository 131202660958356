import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack, Typography, tableCellClasses, Checkbox, InputAdornment, Icon, IconButton, Chip } from '@mui/material';
import { getRawDataImage } from '../../services/imageServices';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';
import { getRawDataFile } from '../../services/publicationService';
import { Add, AddAPhoto, Download, Edit, Delete, AddBox, AttachMoney, ArrowDownward, ArrowUpward, Cancel } from '@mui/icons-material';
import PreviewImage from '../newPublication/PreviewImage';
import axios from 'axios';
import { createRawData, moveRawDataDown, moveRawDataUp, updateRawData, deleteRawData } from '../../services/editRawDataService';
import { LoadingButton } from '@mui/lab';
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
// Styled TableCell for customized styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function RawDataTable({ edit = false, rawData, folderId }) {
    const [imageUrls, setImageUrls] = useState({});
    const [initRawData, setInitRawData] = useState(rawData || [])
    const { token } = useUserContext();
    const [editOpen, setEditOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [editView, setEditView] = useState(null)
    const [formValues, setFormValues] = useState({
        name: '',
        comment: '',
        previewImage: null,
        rawData: null,
        priceSuggestion: ''
    });
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteLoadingRawData, setDeleteLoadingRawData] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [rawDataIdToDelete, setRawDataIdToDelete] = useState(null);
    const uploadRawData = async () => {
        setUploadLoading(true); // Set loading to true before the request

        console.log("folderId", folderId);

        try {
            // Make the API request to upload the raw data
            const response = await createRawData(folderId, formValues, token);

            // Update the state with the new data
            setInitRawData(prev => [...prev, response]);

            console.log("Data uploaded successfully.");
            console.log(response); // Log the successful response
        } catch (error) {
            // Handle any errors that occur during the request
            console.error("Error uploading data:", error);
        } finally {
            // Set loading to false after the request completes (either success or error)
            setUploadLoading(false);
        }
    };

    const fetchUpdateRawData = async (data) => {
        setUpdateLoading(true); // Set loading to true at the start
        try {
            const response = await updateRawData(data.id, selectedData, token);
            setInitRawData((prevData) =>
                prevData.map((item) =>
                    item.id === data.id ? { ...item, ...response } : item
                )
            );
            fetchImage(response.id)
            setEditView(null)
            setSelectedData(null)
        } catch (error) {
            console.error("Failed to update raw data:", error);
        } finally {
            setUpdateLoading(false); // Set loading to false after completion
        }
    };

    const downloadRawData = async (id) => {
        try {
            const pdfFileUrl = await getRawDataFile(token, id);

            if (!pdfFileUrl || pdfFileUrl.startsWith('PDF file not found')) {
                throw new Error('Failed to fetch PDF file URL.');
            }

            window.location.href = pdfFileUrl;
        } catch (err) {
            console.error(`Download failed: ${err.message}`);
        }
    };

    const fetchImage = async (id) => {
        try {
            const imageBlob = await getRawDataImage(id);
            const imageObjectUrl = URL.createObjectURL(imageBlob);
            setImageUrls((prev) => ({ ...prev, [id]: imageObjectUrl }));
            setIsCreate(false)
            setFormValues({
                name: '',
                comment: '',
                previewImage: "null",
                rawData: "null",
                priceSuggestion: null
            })
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const handleEditOpen = (data) => {
        setEditView(data.id); // Set the edit view to the selected row's id
        setSelectedData({
            name: data.name || '',
            previewImage: data.previewImage || "null",
            rawData: data.rawData || "null",
            comment: data.comment || '',
            priceSuggestion: data.priceSuggestion || null,
        });
    };
    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setSelectedData((prev) => ({ ...prev, [name]: files ? files[0] : value }));
    };

    const handleEditClose = () => {
        setEditView(null)
        setEditOpen(false);
        setSelectedData(null);
    };

    const handleEditSave = async () => {
        if (!selectedData) return;

        try {
            await axios.put(`/api/rawData/${selectedData.id}`, {
                name: selectedData.name,
                comment: selectedData.comment,
                priceSuggestion: selectedData.priceSuggestion
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setEditOpen(false);
        } catch (error) {
            console.error('Error updating raw data:', error);
        }
    };



    const handleFieldChange = (e) => {
        const { name, value, files } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: files ? files[0] : value }));
    };

    useEffect(() => {
        initRawData.forEach((data) => {
            if (!imageUrls[data.id]) {
                fetchImage(data.id);
            }
        });

        return () => {
            Object.values(imageUrls).forEach((url) => URL.revokeObjectURL(url));
        };
    }, [rawData, initRawData, selectedData]);

    const handleStartCreateRawData = () => {
        setIsCreate(true);
        setFormValues({
            name: '',
            comment: '',
            previewImage: null,
            rawData: null,
            priceSuggestion: ''
        })
    };
    const handleEndRawData = () => {
        setIsCreate(false)
        setFormValues({
            name: '',
            comment: '',
            previewImage: null,
            rawData: null,
            priceSuggestion: ''
        })
    }
    const handleDownload = (name) => {
        const file = formValues[name] || selectedData[name];
        if (file) {
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    };

    const removeFile = (name) => {
        setSelectedData((prev) => ({ ...prev, [name]: null }));
        setFormValues((prev) => ({ ...prev, [name]: null }))
    };
    const handleMoveUp = async (data) => {
        try {
            // Move the raw data up
            await moveRawDataUp(data.id, token);

            // After moving, update the initRawData state and imageUrls accordingly
            const updatedRawData = [...initRawData]; // Copy to avoid mutating the state directly
            const index = updatedRawData.findIndex((item) => item.id === data.id);
            if (index > 0) {
                const movedItem = updatedRawData.splice(index, 1)[0];  // Remove the moved item
                updatedRawData.splice(index - 1, 0, movedItem);  // Insert it in the new position

                // Update the displayOrder for the moved item and the one it swapped with
                updatedRawData[index - 1].displayOrder = index - 1;
                updatedRawData[index].displayOrder = index;

                setInitRawData(updatedRawData); // Update raw data

                setImageUrls((prevUrls) => {
                    const updatedUrls = { ...prevUrls }; // Copy the existing URLs
                    // Ensure the moved item's image URL is still correct
                    updatedUrls[movedItem.id] = movedItem.previewImage;
                    return updatedUrls; // Return updated image URLs
                });
            }

            console.log('Raw data moved up');
        } catch (error) {
            console.error('Error moving raw data up:', error);
        }
    };

    const handleMoveDown = async (data) => {
        try {
            // Move the raw data down
            await moveRawDataDown(data.id, token);

            // After moving, update the initRawData state and imageUrls accordingly
            const updatedRawData = [...initRawData]; // Copy to avoid mutating the state directly
            const index = updatedRawData.findIndex((item) => item.id === data.id);
            if (index < updatedRawData.length - 1) {
                const movedItem = updatedRawData.splice(index, 1)[0];  // Remove the moved item
                updatedRawData.splice(index + 1, 0, movedItem);  // Insert it in the new position

                // Update the displayOrder for the moved item and the one it swapped with
                updatedRawData[index + 1].displayOrder = index + 1;
                updatedRawData[index].displayOrder = index;

                setInitRawData(updatedRawData); // Update raw data

                setImageUrls((prevUrls) => {
                    const updatedUrls = { ...prevUrls }; // Copy the existing URLs
                    // Ensure the moved item's image URL is still correct
                    updatedUrls[movedItem.id] = movedItem.previewImage;
                    return updatedUrls; // Return updated image URLs
                });
            }

            console.log('Raw data moved down');
        } catch (error) {
            console.error('Error moving raw data down:', error);
        }
    };
    if (!initRawData) {
        return "Loading..."
    }


    const handleOpenDialog = (rawDataId) => {
        setRawDataIdToDelete(rawDataId);  // Store the ID of the data to delete
        setOpenDialog(true);  // Open the dialog
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);  // Close the dialog without doing anything
        setRawDataIdToDelete(null);
    };

    const handleConfirmDelete = async () => {
        setDeleteLoading(true); // Set loading to true when the delete operation starts
        try {
            // Call the delete service (assumes you have a deleteRawData function)
            await deleteRawData(rawDataIdToDelete, token);

            // Update the state to remove the deleted item from the UI
            setInitRawData(prevData => {
                const updatedData = prevData.filter(item => item.id !== rawDataIdToDelete);

                // Reassign displayOrder for the remaining items
                updatedData.forEach((item, index) => {
                    item.displayOrder = index + 1; // Update displayOrder to be sequential starting from 1
                });

                return updatedData;
            });

            // Optionally, show a success message
            // toast.success("Raw Data deleted successfully!");

        } catch (error) {
            console.error("Error deleting raw data:", error);
            // Optionally, show an error message
            // toast.error("Failed to delete raw data.");
        } finally {
            setDeleteLoading(false); // Set loading to false after the operation ends
            setOpenDialog(false); // Close the dialog after confirming the delete
        }
    };
    const handleDelete = async (rawDataId) => {
        setDeleteLoading(true); // Set loading to true when the delete operation starts
        try {
            // Call the delete service
            await deleteRawData(rawDataId, token);

            // Update the state to remove the deleted item from the UI
            setInitRawData(prevData => {
                const updatedData = prevData.filter(item => item.id !== rawDataId);

                // Reassign displayOrder for the remaining items
                updatedData.forEach((item, index) => {
                    item.displayOrder = index + 1; // Update displayOrder to be sequential starting from 1
                });

                // Optionally, save the updated order back to the server if needed
                // await updateDisplayOrderOnServer(updatedData);

                return updatedData;
            });

            // Optionally, you can display a success message
            // toast.success("Raw Data deleted successfully!");

        } catch (error) {
            // Handle error (e.g., show an error message)
            console.error("Error deleting raw data:", error);
        } finally {
            setDeleteLoading(false); // Set loading to false after the operation ends
        }
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {
                            (edit && !isCreate) && <StyledTableCell>Order</StyledTableCell>
                        }
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Preview Image</StyledTableCell>
                        <StyledTableCell>Raw Data</StyledTableCell>
                        <StyledTableCell>Comment</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>Price Suggestion</StyledTableCell>

                        {(edit && !isCreate) && <StyledTableCell>Actions</StyledTableCell>
                        }

                    </TableRow>
                </TableHead>
                <TableBody>
                    {initRawData.map((data) => (
                        (editView == null || editView !== data.id) ? (
                            <StyledTableRow key={data.id}>
                                {(edit && !isCreate) && <StyledTableCell>
                                    <Stack direction="row" alignItems="center" p={0.5}>
                                        <Chip label={data.displayOrder} />
                                        <Stack alignItems="center">
                                            <IconButton
                                                disabled={data.displayOrder === 0}
                                                onClick={() => handleMoveUp(data)}
                                            >
                                                <ArrowUpward />
                                            </IconButton>
                                            <IconButton
                                                disabled={data.displayOrder === initRawData.length - 1}
                                                onClick={() => handleMoveDown(data)}
                                            >
                                                <ArrowDownward />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </StyledTableCell>}
                                <StyledTableCell sx={{ minWidth: 200 }}>{data.name}</StyledTableCell>
                                <StyledTableCell>
                                    <PreviewImage imageUrl={imageUrls[data.id]} alt={data.name} />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Stack direction="row" alignItems="center" spacing={0.5}>
                                        <Link onClick={() => downloadRawData(data.id)}>{data.rawDataName?.slice(37)}</Link>
                                        <Download />
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell width={400}>{data.comment}</StyledTableCell>
                                <StyledTableCell>{data.priceSuggestion == 0 ? "free" : data.priceSuggestion + "$"}</StyledTableCell>
                                {edit && (
                                    <StyledTableCell>
                                        <Button onClick={() => handleEditOpen(data)} startIcon={<Edit />}>Edit</Button>
                                        <LoadingButton
                                            color="error"
                                            loading={deleteLoading}
                                            onClick={() => handleOpenDialog(data.id)} // Open the confirmation dialog when clicked
                                            startIcon={<Delete />}
                                        >
                                            Delete
                                        </LoadingButton>

                                        {/* Delete confirmation dialog */}
                                        <DeleteConfirmationDialog
                                            open={openDialog}
                                            onClose={handleCloseDialog}
                                            onConfirm={handleConfirmDelete}
                                            deleteLoading={deleteLoading}
                                        />


                                    </StyledTableCell>
                                )}
                            </StyledTableRow>
                        ) : (
                            <StyledTableRow key={selectedData.id}>
                                <StyledTableCell>
                                    <Stack direction="row" alignItems="center" p={0.5}>
                                        <Chip label={data.displayOrder} />
                                        <Stack alignItems="center">
                                            <IconButton
                                                disabled={data.displayOrder === 0}
                                                onClick={() => handleMoveUp(data)}
                                            >
                                                <ArrowUpward />
                                            </IconButton>
                                            <IconButton
                                                disabled={data.displayOrder === initRawData.length - 1}
                                                onClick={() => handleMoveDown(data)}
                                            >
                                                <ArrowDownward />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        label="Specimen Name"
                                        value={selectedData.name}
                                        name="name"
                                        size="small"
                                        onChange={handleInputChange}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Stack spacing={1}>
                                        {selectedData.previewImage == null ? (
                                            <Button
                                                size="small"
                                                component="label"
                                                variant="contained"
                                                startIcon={<AddAPhoto />}
                                            >
                                                Update Preview Image
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    name="previewImage"
                                                    onChange={handleInputChange}
                                                />
                                            </Button>
                                        ) : (
                                            <>
                                                {selectedData.previewImage == "null" ? <><PreviewImage imageUrl={imageUrls[data.id]} alt={data.name} /></>
                                                    :
                                                    <>
                                                        <img width={80} height={80} src={URL.createObjectURL(selectedData.previewImage)} />
                                                    </>
                                                }

                                                <Button
                                                    size="small"
                                                    color="error"
                                                    variant="outlined"
                                                    onClick={() => removeFile('previewImage')}
                                                >
                                                    <Delete />
                                                </Button>
                                            </>
                                        )}
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack spacing={1}>
                                        {!selectedData.rawData ? (
                                            <Button
                                                size='small'
                                                component="label"
                                                variant="contained"
                                                startIcon={<AddBox />}
                                            >
                                                Update Raw Data File
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    name="rawData"
                                                    onChange={handleInputChange}
                                                />
                                            </Button>
                                        ) : (
                                            <>
                                                <Link onClick={() => downloadRawData(data.id)}>{data.rawDataName?.slice(37)}</Link>
                                                <Download />
                                            </>

                                        )}
                                        {selectedData.rawData && (
                                            <Button
                                                size="small"
                                                color="error"
                                                variant="outlined"
                                                onClick={() => removeFile('rawData')}
                                            >
                                                <Delete />
                                            </Button>
                                        )}
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <TextField
                                        fullWidth
                                        label="Comment (Optional)"
                                        size="small"
                                        multiline
                                        rows={3}
                                        name="comment"
                                        sx={{ width: 350 }}
                                        value={selectedData.comment}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            if (value.length <= 255) {
                                                setSelectedData((prev) => ({ ...prev, [name]: value }));
                                            }
                                        }}
                                        helperText={`${selectedData.comment.length}/255 characters`}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack spacing={1}>

                                        <TextField
                                            fullWidth
                                            label="Price Suggestion"
                                            size="small"
                                            type="number"
                                            name="priceSuggestion"
                                            placeholder="Enter price suggestion"
                                            value={selectedData.priceSuggestion ?? ""}
                                            disabled={selectedData.priceSuggestion === 0}
                                            onChange={handleInputChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AttachMoney />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <Stack direction="row" alignItems="center">
                                            <Checkbox
                                                sx={{ color: "primary.main" }}
                                                checked={selectedData.priceSuggestion === 0}
                                                onChange={(e) => {
                                                    setSelectedData((prev) => ({
                                                        ...prev,
                                                        priceSuggestion: e.target.checked ? 0 : null,
                                                    }));
                                                }}
                                            />
                                            <Typography variant="body2">Free</Typography>
                                        </Stack>
                                    </Stack>
                                </StyledTableCell>

                                <StyledTableCell>
                                    <Stack>
                                        {editView &&
                                            <>
                                                <LoadingButton
                                                    loading={updateLoading}
                                                    disabled={
                                                        !selectedData.name ||
                                                        !selectedData.previewImage ||
                                                        !selectedData.rawData ||
                                                        selectedData.priceSuggestion == null || // null veya undefined kontrolü
                                                        selectedData.priceSuggestion < 0 // Fiyat önerisi negatif olamaz
                                                    }
                                                    onClick={() => fetchUpdateRawData(data)}
                                                >
                                                    {editView ? "Update" : "Upload"}
                                                </LoadingButton>

                                            </>

                                        }

                                    </Stack>

                                </StyledTableCell>
                            </StyledTableRow>
                        )
                    ))}


                    {isCreate && (
                        <StyledTableRow>
                            <StyledTableCell>
                                <TextField

                                    label="Specimen Name"
                                    value={formValues.name}
                                    name="name"
                                    size="small"
                                    onChange={handleFieldChange}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Stack spacing={1}>
                                    {formValues.previewImage == null ? (
                                        <Button
                                            size="small"
                                            component="label"
                                            variant="contained"
                                            startIcon={<AddAPhoto />}
                                        >
                                            Upload Preview Image
                                            <VisuallyHiddenInput
                                                type="file"
                                                name="previewImage"
                                                onChange={handleFieldChange}
                                            />
                                        </Button>
                                    ) : (
                                        <>
                                            <Typography
                                                variant="body2"
                                                onClick={() => handleDownload('previewImage')}
                                                style={{ cursor: 'pointer', color: 'blue' }}
                                            >
                                                {formValues.previewImage?.name}
                                            </Typography>
                                            <Button
                                                size="small"
                                                color="error"
                                                variant="outlined"
                                                onClick={() => removeFile('previewImage')}
                                            >
                                                <Delete />
                                            </Button>
                                        </>
                                    )}
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="center" sx={{ maxWidth: 300 }}>
                                <Stack spacing={1}>
                                    {formValues.rawData == null ?
                                        <Button
                                            size='small'
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<AddBox />}
                                        >
                                            Upload Raw Data File
                                            <VisuallyHiddenInput
                                                name='rawData'
                                                type="file"
                                                onChange={handleFieldChange}
                                            />
                                        </Button> : (
                                            <>
                                                <Typography
                                                    name='rawData'
                                                    variant="body2"
                                                    onClick={(e) => handleDownload('rawData')}
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                >
                                                    {formValues.rawData?.name}
                                                </Typography>
                                                <Button
                                                    name='rawData'
                                                    size='small'
                                                    color='error'
                                                    variant="outlined"
                                                    onClick={() => removeFile('rawData')}
                                                >
                                                    <Delete />
                                                </Button>
                                            </>
                                        )}

                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TextField
                                    fullWidth
                                    sx={{ minWidth: 200, maxWidth: 200 }}
                                    label="Comment (Optional)"
                                    size='small'
                                    multiline
                                    rows={3}
                                    name='comment'
                                    value={formValues.comment}
                                    onChange={handleFieldChange}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Stack>
                                    <TextField
                                        disabled={formValues.priceSuggestion === 0}
                                        fullWidth
                                        label="Price Suggestion"
                                        size="small"
                                        type="number"
                                        name="priceSuggestion"
                                        value={formValues.priceSuggestion}
                                        onChange={handleFieldChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoney />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Stack direction="row" alignItems="center">
                                        <Checkbox
                                            sx={{ color: "primary.main" }}
                                            checked={formValues.priceSuggestion === 0}
                                            onChange={(e) => {
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    priceSuggestion: e.target.checked ? 0 : 15,
                                                }));
                                            }}
                                        />
                                        <Typography variant="body2">Free</Typography>
                                    </Stack>

                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell>
                                <LoadingButton
                                    loading={uploadLoading}
                                    disabled={
                                        formValues.name === '' ||
                                        formValues.previewImage == null ||
                                        formValues.rawData == null ||
                                        formValues.priceSuggestion == null ||
                                        (formValues.priceSuggestion === '')
                                    }
                                    onClick={uploadRawData}
                                >
                                    Upload
                                </LoadingButton>
                                <Button onClick={handleEndRawData} startIcon={<Cancel />} color="error">
                                    Cancel
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    )}
                </TableBody>
            </Table>
            {edit && <Stack direction="row" alignItems="center" justifyContent="center" p={1}>
                <Button onClick={handleStartCreateRawData} variant="contained" startIcon={<Add />}>
                    Add raw data
                </Button>
            </Stack>}

            <Dialog open={editOpen} onClose={handleEditClose}>
                <DialogTitle>Edit Raw Data</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={selectedData?.name || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="comment"
                        label="Comment"
                        fullWidth
                        variant="outlined"
                        value={selectedData?.comment || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="priceSuggestion"
                        label="Price Suggestion"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={selectedData?.priceSuggestion || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
}
