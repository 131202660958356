import React, { useEffect, useState } from 'react';
import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
    CircularProgress,
    Box,
    Button,
    Stack,
    Select,
    MenuItem,
    Pagination,
    Divider,
    Link,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import SchoolIcon from '@mui/icons-material/School';
import BookIcon from '@mui/icons-material/Book';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Science from '@mui/icons-material/Science';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import axios from 'axios';
import { useUserContext } from '../auth/AuthProvider';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, Email, ErrorOutline, ExpandMore, MenuBook, Money, SchemaOutlined } from '@mui/icons-material';
import CommentManagement from '../components/library/CommentManagement';

const baseUrl = process.env.REACT_APP_BASE_URL;

const publications = [
    { id: 0, key: "Article", value: "Article", icon: <ArticleIcon />, endpoint: "/user-articles" },
    { id: 1, key: "Thesis", value: "Thesis", icon: <SchoolIcon />, endpoint: "/user-thesis" },
    { id: 2, key: "Chapter in a book", value: "Chapter in a book", icon: <BookIcon />, endpoint: "/user-chapter-in-a-book" },
    { id: 3, key: "Conference paper", value: "Conference paper", icon: <AssignmentIcon />, endpoint: "/user-conference-paper" },
    { id: 4, key: "Research project", value: "Research project", icon: <Science />, endpoint: "/user-research-project" },
    { id: 5, key: "Company test report", value: "Company test report", icon: <BusinessCenterIcon />, endpoint: "/user-company-test-report" },
    { id: 6, key: "Book", value: "Book", icon: <MenuBook />, endpoint: "/user-book" },
    { id: 7, key: "Letter", value: "Letter", icon: <Email />, endpoint: "/user-letter" },
    { id: 8, key: "Retracted article", value: "Retracted article", icon: <ErrorOutline />, endpoint: "/user-retracted-article" },
    { id: 9, key: "Editorial material", value: "Editorial Material", icon: <EditOutlined />, endpoint: "/user-editorial-material" },
    { id: 10, key: "Preprint", value: "Preprint", icon: <SchemaOutlined />, endpoint: "/user-preprint" }
];


const PublicationPage = ({ library = false }) => {
    const [publicationsData, setPublicationsData] = useState([]);
    const [data, setData] = useState([]); // This will hold the publication counts
    const [loading, setLoading] = useState(false);
    const { userId, type } = useParams();
    const [isSizeLoading, setIsSizeLoading] = useState(false);
    const [selectedType, setSelectedType] = useState(type || "Article");
    const [allPublicationSize, setAllPublicationSize] = useState(0);
    const [selectedSize, setSelectedSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const { token, user } = useUserContext();
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handlePublishComment = () => {
        navigate("/library/publish-comment")
    };

    const handleSaveAsDraft = () => {
        navigate("/library/save-as-draft")
    };
    const sizeOptions = [10, 20, 30, 50];

    const fetchPublications = async (type, size, page) => {
        const publication = publications.find(pub => pub.value === type);
        if (!publication) return;

        setLoading(!isSizeLoading);
        try {
            const response = await axios.get(`${baseUrl}/api/v1/publication${publication.endpoint}`, {
                params: {
                    userId: userId ? userId : user.id,
                    page: page - 1,
                    size,
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            const fetchedPublications = response.data.publications || [];
            setPublicationsData(fetchedPublications);
            setTotalPages(response.data.totalPages || 0);
            setAllPublicationSize(response.data.allPages || 0);
        } catch (error) {
            console.error("Error fetching publications:", error);
            setPublicationsData([]);
        } finally {
            setLoading(false);
            setIsSizeLoading(false);
        }
    };

    const fetchPublicData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/v1/no-auth/user/${userId ? userId : user.id}/publication-counts`);
            console.log(response.data)
            setData([
                { id: 0, value: response.data.articleCount, label: "Article" },
                { id: 1, value: response.data.thesisCount, label: "Thesis" },
                { id: 2, value: response.data.chapterInABook, label: "Chapter in a book" },
                { id: 3, value: response.data.conferencePaperCount, label: "Conference paper" },
                { id: 4, value: response.data.researchProjectCount, label: "Research project" },
                { id: 5, value: response.data.companyTestReportCount, label: "Company test report" },
                { id: 6, value: response.data.bookCount, label: "Book" },
                { id: 7, value: response.data.letterCount, label: "Letter" },
                { id: 8, value: response.data.retractedArticleCount, label: "Retracted article" },
                { id: 9, value: response.data.editorialMaterialCount, label: "Editorial material" },
                { id: 10, value: response.data.preprintCount, label: "Preprint" },
            ]);
        } catch (error) {
            console.error("Error fetching publication counts:", error);
        }
    };


    useEffect(() => {
        fetchPublicData();
    }, []);

    const handlePublicationClick = (type) => {
        navigate(`/library/publication/${type}`);
        setSelectedType(type);
        setCurrentPage(1);
    };

    function getYearFromDate(dateString) {
        // Tarih stringini kontrol et
        if (typeof dateString !== 'string' || !dateString) {
            return
        }

        // Tarih stringini parçala
        const parts = dateString.split('-');

        // Yıl kısmını döndür
        if (parts.length === 3) {
            return parts[0]; // Yıl kısmı
        } else {
            return;
        }
    }

    return (
        <Container >
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px' }}>
                        <Typography variant="h5" gutterBottom>
                            {library ? "My publications" : "Publications"}
                        </Typography>
                        {publications.map((pub) => {
                            const pubCount = data.find(d => d.label === pub.key)?.value || 0; // Get count for each publication type
                            return (
                                <Grid item key={pub.id}>
                                    <Button
                                        variant={selectedType === pub.value ? "contained" : "outlined"}
                                        onClick={() => handlePublicationClick(pub.value)}
                                        startIcon={pub.icon}
                                        fullWidth
                                        sx={{
                                            marginBottom: 1,
                                            borderRadius: '8px',
                                            '&:hover': {
                                                backgroundColor: selectedType === pub.value ? '#0056b3' : '#e0e0e0',
                                            },
                                        }}
                                    >
                                        {pub.key} ({pubCount})  {/* Display count next to publication type */}
                                    </Button>
                                </Grid>
                            );
                        })}

                    </Paper>
                    <Accordion sx={{ borderRadius: 3, mt: 2 }} expanded={expanded} onChange={handleAccordionChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h6' color="primary.main">Manage Your Comments</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ marginBottom: 1 }}
                                onClick={handlePublishComment}
                            >
                                Publish Comment
                            </Button>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onClick={handleSaveAsDraft}
                            >
                                Save as Draft Comment
                            </Button>
                        </AccordionDetails>
                    </Accordion>

                    <Paper sx={{ mt: 2 }}>
                        <Stack>
                            <Divider />
                            <Button size='large' startIcon={<Money />}>
                                purchased data
                            </Button>

                        </Stack>
                    </Paper>


                </Grid>
                <Grid item xs={12} md={8}>
                    <Outlet />
                </Grid>


            </Grid>
        </Container>
    );
};

export default PublicationPage;
