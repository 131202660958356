import { Container, Paper, Typography, CircularProgress, Alert, Stack, Button, Divider, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPreprintPage } from '../services/publicationService';
import { useUserContext } from '../auth/AuthProvider';
import { PostHeader } from '../components/publicationPosts/PostHeader';
import { PostComment } from '../components/publicationPosts/PostComment';
import PostShareByUser from '../components/publicationPosts/PostShareByUser';
import PostActionButton from '../components/publicationPosts/PostActionButton';
import { ArrowBack, Info } from '@mui/icons-material';
import PostAuthors from '../components/publicationPosts/PostAuthors';
import Comment from '../components/preprint/Comment';
import { useTheme } from '@emotion/react';
import { deleteComment, getSummries } from '../services/commentServices';
import { LoadingButton } from '@mui/lab';

const CommentPage = () => {
    const { publicationTitle } = useParams();
    const [publication, setPublication] = useState(null);
    const [summries, setSummries] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [alerts, setAlerts] = useState([]); // Changed to an array to hold multiple alerts
    const { token } = useUserContext();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchPublication = async () => {
            try {
                const data = await getPreprintPage(token, publicationTitle);
                setPublication(data);
                const summries = await getSummries(token, data.id);
                console.log(data)
                setSummries(summries)
            } catch (error) {
                setAlerts(prevAlerts => [...prevAlerts, { severity: 'error', message: 'Failed to fetch publication data' }]); // Add error alert to array
            } finally {
                setLoading(false);
            }
        };

        fetchPublication();
    }, [publicationTitle, token]);

    if (loading) {
        return (
            <Container sx={{ mt: 1 }}>
                <Paper sx={{ padding: 2, textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography>Loading...</Typography>
                </Paper>
            </Container>
        );
    }
    const handleDelete = async (commentId) => {
        setDeleteLoading(true); // Show loading state
        try {
            // Call the service to delete the comment
            await deleteComment(token, commentId);

            // Remove the deleted comment from the `summries` array
            setSummries((prevSummaries) =>
                prevSummaries.filter((summary) => summary.id !== commentId)
            );
        } catch (error) {
            console.error("Error deleting comment:", error);
        } finally {
            setDeleteLoading(false); // Reset loading state
        }
    };
    return (
        <Container sx={{ mt: 1 }}>
            <Paper sx={{ padding: 2, borderRadius: 3 }}>
                {/* Render Alerts */}
                {summries?.length > 0 && (

                    <Stack sx={{ mb: 2, borderRadius: 2, overflow: 'hidden', boxShadow: 3, bgcolor: 'background.paper' }}>
                        <Alert
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                bgcolor: 'background.default',
                            }}
                            icon={<Info sx={{ color: 'primary.main' }} />}
                            severity={alert.severity}
                        >
                            {/* Header */}
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 2,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    color: 'primary.main',
                                    textTransform: 'uppercase',
                                }}
                            >
                                DRAFT COMMENTS
                            </Typography>

                            {/* List of Comments */}
                            {summries.map((alert, index) => (
                                <Stack
                                    key={index}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        bgcolor: index % 2 === 0 ? 'grey.100' : 'grey.50',
                                        boxShadow: 1,
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'translateY(-2px)',
                                            boxShadow: 3,
                                        },
                                    }}
                                >
                                    {/* Comment Content */}
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            flex: 1,
                                            fontWeight: 'medium',
                                            color: 'text.primary',
                                        }}
                                    >
                                        {alert.generalComment.slice(0, 50)}...
                                    </Typography>

                                    {/* Action Buttons */}
                                    <Stack direction="row" spacing={1}>
                                        <LoadingButton
                                           href={`/comments/${alert.id}/update`}
                                            sx={{
                                                color: 'primary.main',
                                                fontWeight: 'bold',
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                                '&:hover': { textDecoration: 'underline' },
                                            }}
                                        >
                                            Edit
                                        </LoadingButton>
                                        <LoadingButton
                                            onClick={() => { handleDelete(alert.id) }}
                                            loading={deleteLoading}
                                            sx={{
                                                color: 'error.main',
                                                fontWeight: 'bold',
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                                '&:hover': { textDecoration: 'underline' },
                                            }}
                                        >
                                            Delete
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            ))}
                        </Alert>
                    </Stack>

                )}

                <Stack spacing={2}>
                    <Stack direction="row" p={1} justifyContent="space-between" alignItems="center">
                        <Button onClick={handleBackClick} variant='outlined' startIcon={<ArrowBack />}>Back</Button>

                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            justifyContent={isMobile ? 'center' : 'space-between'}
                            alignItems={isMobile ? 'flex-start' : 'center'}
                            spacing={2}
                        >
                            <PostShareByUser shareByAuthor={publication.shareByAuthor} />
                            <PostActionButton data={publication} />
                        </Stack>
                    </Stack>

                    <Stack spacing={1}>
                        <PostHeader variant='h4' title={publication.title} id={publication.id} slug={publication.slug}/>
                        <Typography color="gray">{publication.summary}</Typography>
                    </Stack>
                    <PostAuthors authors={publication.authors} />
                    <Comment haveArticle={publication.haveArticle} publicationTitle={publicationTitle} preprintId={publication.id} />
                </Stack>
            </Paper>
        </Container>
    );
};

export default CommentPage;
