import { Paper, Stack, Divider, useMediaQuery, useTheme, Box, Typography, Button, AvatarGroup, Avatar } from '@mui/material';
import React, { useEffect } from 'react';
import { PostHeader } from '../publicationPosts/PostHeader';
import { PostComment } from '../publicationPosts/PostComment';
import PostShareByUser from '../publicationPosts/PostShareByUser';
import PostAuthors from '../publicationPosts/PostAuthors';
import PostActionButton from '../publicationPosts/PostActionButton';
import PostFolders from '../publicationPosts/PostFolders';
import PostChip from '../publicationPosts/PostChip';
import { Comment } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const PreprintPost = ({ data, publicStatus = false }) => {
  const { publication } = data;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    console.log("Preprint", data)
  }, [])

  const getTimeElapsed = (time) => {
    if (!time || isNaN(new Date(time))) {
      return null; // Geçersiz zaman durumunda uygun bir geri dönüş
    }

    const now = new Date();
    const createdAt = new Date(time);
    const elapsedSeconds = Math.floor((now - createdAt) / 1000);

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

    if (elapsedSeconds < 60) {
      return rtf.format(-elapsedSeconds, 'second');
    }
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    if (elapsedMinutes < 60) {
      return rtf.format(-elapsedMinutes, 'minute');
    }
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    if (elapsedHours < 24) {
      return rtf.format(-elapsedHours, 'hour');
    }
    const elapsedDays = Math.floor(elapsedHours / 24);
    return rtf.format(-elapsedDays, 'day');
  };


  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 4,
        p: isSmallScreen ? 2 : 3, // Adjust padding based on screen size
        backgroundColor: 'background.default',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
        transition: 'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
        '&:hover': {
          transform: 'scale(1.026)',
          boxShadow: '0px 12px 36px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction={isSmallScreen ? 'column' : 'row'} // Change direction based on screen size
          justifyContent="space-between"
          alignItems="center"
        >
          <PostShareByUser shareByAuthor={data.shareByAuthor} />
          <PostActionButton data={data} />
        </Stack>

        <PostHeader variant={publicStatus ? 'h6' : 'h5'} title={publication.title} id={publication.id} slug={publication.slug} />
        <PostComment
          variant={publicStatus ? 'body1' : undefined}
          comment={publication.comment}
          commentType={
            publication.commentType
              ? publication.commentType
              : data.type == "RETRACTED_ARTICLE"
                ? "Retraction"
                : undefined
          }
        />

        <Stack
          direction={isSmallScreen ? 'column' : 'row'} // Change direction based on screen size
          spacing={1}
          justifyContent="space-between"
        >
          <PostChip data={data} />
        </Stack>
        <PostFolders publicStatus={publicStatus} folders={data.folders} />
        {data.type !== "THESIS" && <PostAuthors authors={data.authors} />}
        {data.connectTitle && <Stack sx={{ border: "1px solid", borderColor: "primary.main", p: 1, borderRadius: 3 }}>
          <Typography>Published Publication:</Typography>
          <PostHeader
            title={data.connectTitle
              .toLowerCase()
              .replaceAll(/[áàâãäå]/g, "a")
              .replaceAll(/[éèêë]/g, "e")
              .replaceAll(/[íìîïı]/g, "i")
              .replaceAll(/[óòôõö]/g, "o")
              .replaceAll(/[úùûü]/g, "u")
              .replaceAll(/[ç]/g, "c")
              .replaceAll(/[ñ]/g, "n")
              .replaceAll(/[ž]/g, "z")
              .replaceAll(/[š]/g, "s")
              .replaceAll(/[ýÿ]/g, "y")
              .replaceAll(/[^a-z0-9\s-]/g, "") // Remove special characters
              .trim() // Trim whitespace from start and end
              .replaceAll(/\s+/g, "-") // Replace spaces with hyphens
              .replaceAll(/-{2,}/g, "-") // Replace multiple hyphens with a single hyphen
            }
            haveArticle={true}
          />
        </Stack>}
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignContent="end">
            {getTimeElapsed(data.creationTime) && <Typography>{getTimeElapsed(data.creationTime)}</Typography>}
          </Stack>
          <>

            <Link
              to={`/comments/${data.publication.slug}`}
              style={{ textDecoration: "none" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  ":hover": { backgroundColor: "rgba(0, 123, 255, 0.1)" },
                  p: 2,
                  borderRadius: 3,
                  boxShadow: 1,
                  transition: "background-color 0.3s ease, transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <AvatarGroup
                  sx={{
                    "& .MuiAvatar-root": {
                      width: 30,
                      height: 30,
                      fontSize: "1rem",
                      border: "2px solid",
                      borderColor: "primary.main",
                    },
                  }}
                  total={data.commentCount > 99 ? 99 : data.commentCount} // Limit total display
                  renderSurplus={(surplus) => (
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "text.secondary",
                        fontWeight: 600,
                      }}
                    >
                      +{surplus > 1000 ? (surplus / 1000).toFixed(1) + "k" : surplus}
                    </Typography>
                  )}
                >
                  {data.commentAuthors.map((author, index) => (
                    <Avatar
                      key={index} // Ensure each avatar has a unique key
                      alt={`${author.firstname} ${author.lastname}`}
                      src={author.imageUrl || ""}
                    >
                      {!author.imageUrl &&
                        `${author.firstname?.[0] || ""}${author.lastname?.[0] || ""}`}
                    </Avatar>
                  ))}
                </AvatarGroup>

                <Box ml={2}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1rem",
                      color: "text.primary",
                    }}
                  >
                    Reviewer comments ({data.commentCount})
                  </Typography>
                </Box>
              </Stack>
            </Link>
          </>

        </Stack>

      </Stack>

    </Paper>
  );
};

export default PreprintPost;
