import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../auth/AuthProvider';
import { Button, Typography, List, ListItem, ListItemText, CircularProgress, Box, Alert, Paper, ListItemAvatar, ListItemIcon } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PostShareByUser from '../../components/publicationPosts/PostShareByUser';
import { NewReleases, Newspaper, Person, PersonAdd } from '@mui/icons-material';

const baseUrl = process.env.REACT_APP_BASE_URL;

const NotificationPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page (starting from 1)
    const [totalPages, setTotalPages] = useState(0); // Track the total number of pages
    const { token } = useUserContext();

    // Helper function to create headers with the token
    const getAuthHeaders = (token) => {
        return token ? { Authorization: `Bearer ${token}` } : {};
    };

    // Fetch notifications for the user with pagination
    const getNotifications = async (token, page = 1, size = 10) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${baseUrl}/api/v1/notification/notifications`, {
                headers: getAuthHeaders(token),
                params: { page: page - 1, size },
            });
            console.log(response.data)
            setNotifications(response.data.notificationResponses);
            setTotalPages(response.data.totalPages || 1);
        } catch (error) {
            console.error('Error fetching notifications:', error.response?.data || error.message);
            setError(error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch unread notification count for the user
    const getUnreadCount = async (token) => {
        try {
            const response = await axios.get(`${baseUrl}/api/v1/notification/notifications/count`, {
                headers: getAuthHeaders(token),
            });
            setUnreadCount(response.data);
        } catch (error) {
            console.error('Error fetching unread notification count:', error.response?.data || error.message);
        }
    };

    // Use useEffect to load notifications and unread count when the component mounts
    useEffect(() => {
        if (token) {
            getNotifications(token, currentPage);
            getUnreadCount(token);
        }
    }, [token, currentPage]);

    // Pagination handler
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Helper function to extract full name from notification content and make it a link
    const extractAndLinkName = (content, senderingUser) => {
        if (senderingUser?.firstname && senderingUser?.lastname) {
            const fullName = `${senderingUser.firstname} ${senderingUser.lastname}`;
            const regex = new RegExp(`(${fullName})`, 'g');
            // Replace the full name in the content with a clickable link
            return content.replace(regex, `<a href="/researcher/${senderingUser.id}" style="color: #091582; text-decoration: none;">$1</a>`);
        }
        return content;
    };

    if (loading) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
        </Box>
    );
    const getTimeDifference = (dateString) => {
        const now = new Date();
        const createdAt = new Date(dateString);
        const differenceInSeconds = Math.floor((now - createdAt) / 1000);

        if (differenceInSeconds < 60) return `${differenceInSeconds}s`;
        const differenceInMinutes = Math.floor(differenceInSeconds / 60);
        if (differenceInMinutes < 60) return `${differenceInMinutes}m`;
        const differenceInHours = Math.floor(differenceInMinutes / 60);
        if (differenceInHours < 24) return `${differenceInHours}h`;
        const differenceInDays = Math.floor(differenceInHours / 24);
        return `${differenceInDays}d`;
    };
    return (
        <Paper sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>Notifications</Typography>

            {error && <Alert severity="error">{error}</Alert>}

            <List>
                {notifications.length === 0 ? (
                    <Typography variant="body1" color="textSecondary">No notifications available</Typography>
                ) : (
                    notifications.map((notification, index) => (
                        <ListItem key={index} sx={{ padding: 2, borderBottom: '1px solid #ddd' }}>
                            <ListItemIcon>
                                <PersonAdd />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="h6">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: extractAndLinkName(
                                                    notification.content,
                                                    notification.senderingUser
                                                ),
                                            }}
                                        />
                                    </Typography>
                                }
                                secondary={getTimeDifference(notification.createdAt)}
                            />
                        </ListItem>
                    ))
                )}
            </List>

            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Paper>
    );
};

export default NotificationPage;
