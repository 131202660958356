import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useUserContext } from '../../auth/AuthProvider';

export const PostHeader = ({ title, id, variant = "h5", slug, haveArticle = false }) => {
    const {authenticated} = useUserContext()
    return (
        <Link
            to={authenticated ? (haveArticle ? `/publication/${title}` : `/publication/${slug}`) : (haveArticle ? `/rdl/publication/${title}` : `/rdl/publication/${slug}`)} // Encoding and replacing
            style={{
                textDecoration: 'none',
                color: 'inherit'
            }}
        >
            <Typography
                variant={variant}
                color="primary.main"
                sx={{
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline' } // Hover efekti
                }}
            >
                {title}
            </Typography>
        </Link>
    );
};
