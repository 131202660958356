import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Helper function to create headers with the token
const getAuthHeaders = (token) => {
    return token ? { Authorization: `Bearer ${token}` } : {};
};

// Get academic experiences by user ID
export const getAcademicExperienceById = async (userId, type, token) => {
    try {
        let response;
        if (token) {
            response = await axios.get(`${baseUrl}/api/v1/academic-experience/${userId}`, {
                headers: getAuthHeaders(token),
                params: { type },
            });
        } else {
            response = await axios.get(`${baseUrl}/api/v1/no-auth/academic-experience/${userId}`, {
                params: { type },
            });
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching academic experiences:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Get academic experiences for the currently logged-in user
export const getAcademicExperienceForCurrentUser = async (type, token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/academic-experience/edit`, {
            headers: getAuthHeaders(token),
            params: { type },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching academic experiences:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Create a new academic appointment
export const createAcademicAppointment = async (request, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/academic-experience/appointment`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating academic appointment:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Create a new directed thesis
export const createDirectedThesis = async (request, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/academic-experience/thesis`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating directed thesis:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const createThesisJuryMember = async (request, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/academic-experience/thesis-jury-member`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating thesis-jury-member:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const createAcademicDuties = async (request, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/academic-experience/academic-duties`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating thesis-jury-member:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const createStudentProject = async (request, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/academic-experience/student-project`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating thesis-jury-member:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const createStudentCompetition = async (request, token) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/academic-experience/student-competition`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating student-competition:', error.response ? error.response.data : error.message);
        throw error;
    }
};
// Update an existing academic appointment
export const updateThesisJuryMember = async (id, request, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/academic-experience/thesis-jury-member/${id}`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating directed thesis:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const updateStudentCompetition = async (id, request, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/academic-experience/student-competition/${id}`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating directed thesis:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const updateAcademicDuties = async (id, request, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/academic-experience/academic-duties/${id}`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating directed thesis:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const updateStudentProject = async (id, request, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/academic-experience/student-project/${id}`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating directed thesis:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const updateAcademicAppointment = async (id, request, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/academic-experience/appointment/${id}`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating academic appointment:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Update an existing directed thesis
export const updateDirectedThesis = async (id, request, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/academic-experience/thesis/${id}`, request, {
            headers: getAuthHeaders(token),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating directed thesis:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Delete an academic experience by ID
export const deleteAcademicExperience = async (id, token) => {
    try {
        await axios.delete(`${baseUrl}/api/v1/academic-experience/${id}`, {
            headers: getAuthHeaders(token),
        });
    } catch (error) {
        console.error('Error deleting academic experience:', error.response ? error.response.data : error.message);
        throw error;
    }
};