import { Add, Edit, Folder } from '@mui/icons-material'
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import RawDataTable from './RawDataTable'
import ViewExample from '../newPublication/rawdataForm/ViewExample'
import { Link } from 'react-router-dom'

const RawDataInfo = ({ folders, handleEdit, edit, setStep }) => {
    const [foldersList, setFoldersList] = useState(folders)
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <Paper elevation={1}>
            <Stack spacing={2} p={1}>
                <Stack direction="row" justifyContent="end">
                    <IconButton onClick={edit ? () => { handleEdit("RAW_DATA_INFO") } : () => setStep(4)}>
                        <Edit />
                    </IconButton>
                </Stack>
                {
                    foldersList?.length == 0 &&
                    <Paper sx={{ borderRadius: 3, p: 1 }}>
                        <ViewExample handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} setOpen={setOpen} />
                        <Stack p={2} spacing={1}>
                            <Typography color="primary.main" variant='h5' fontWeight="bold">Upload Raw Data</Typography>
                            <Typography variant='h6'>
                                Firstly, to maintain your work in an organized and systematic manner, it is essential to create different folders within your project scope.
                                Each folder should cover a specific area or file type of your project, allowing you to collect and store your data under specific headings.
                                This method makes your data more easily accessible and provides order.
                            </Typography>
                            <Typography variant='h6'>
                                Raw data can be uploaded in different formats (e.g., Excel, Sap 2000, Matlab, Java, Abaqus, SPSS files ext.). <b>If it is a questionary survey</b>, upload all the forms as a merged pdf file.
                                If it is a software modelling upload it as a zip file.
                            </Typography>
                            <Typography variant='h6'>
                                Preview images for raw data are needed to be uploaded by authors.
                                It would be useful to upload an image that gives an idea of what other researchers will have if the corresponding data or model are purchased. The images to be uploaded can sometimes be a graphic image, sometimes an image of software code on a computer, and sometimes an image showing a single page of an entire survey.
                                For questionary survey, upload the first page of the survey form as a jpg image : <b><Link onClick={handleClickOpen}>view example</Link></b>
                            </Typography>
                        </Stack>
                        <Button variant='contained' startIcon={<Add />} fullWidth onClick={edit ? () => { handleEdit("RAW_DATA_INFO") } : () => setStep(4)}>
                            Upload Raw Data
                        </Button>
                    </Paper>
                }
                {
                    foldersList.map((folder) => (
                        <Paper elevation={1} key={folder.id}>
                            <Stack>
                                <Stack p={1}>
                                    <Stack p={1} alignItems="center" direction="row" spacing={0.5}>
                                        <Folder color='primary' />
                                        <Typography color="primary" variant='h6'>{folder.name}</Typography>
                                    </Stack>
                                    <RawDataTable rawData={folder.rawData} />
                                </Stack>
                            </Stack>


                        </Paper>
                    ))
                }
            </Stack>
        </Paper>

    )
}

export default RawDataInfo