import React, { useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ListItemIcon,
    Box,
    Button,
    LinearProgress,
    Stack,
    Divider,
    Typography,
} from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { Link } from 'react-router-dom';
import { FileIcon } from 'react-file-icon';
import { useTheme } from '@emotion/react';
import { fileExtensions } from '../publicationPosts/fileExtentionType';
import axios from 'axios';
import { useUserContext } from '../../auth/AuthProvider';
import { Download, ShoppingBasket } from '@mui/icons-material';
const baseUrl = process.env.REACT_APP_BASE_URL
const SearchResults = ({ searchType, results, imageUrls, fallbackImage }) => {
    const theme = useTheme();
    const { token } = useUserContext()

    const [downloadId, setDownloadId] = useState(null);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0); // Yüzdelik ilerleme durumu
    const handleDownload = async (id, dataName) => {
        try {
            setDownloadId(id);
            setLoading(true); // Start loading state
            setProgress(0); // Reset progress

            // Make the request to get the S3 link
            const response = await axios.get(`${baseUrl}/api/v1/file/raw-data/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Use the returned S3 URL directly
            const s3Url = response.data; // Assume the S3 link is in `s3Link` property
            const anchor = document.createElement("a");
            anchor.href = s3Url;
            anchor.setAttribute("download", dataName); // Use the provided file name
            anchor.click();
        } catch (error) {
            console.error("File could not be downloaded:", error);
        } finally {
            setLoading(false); // Stop loading state
            setProgress(0); // Reset progress
            setDownloadId(null); // Reset downloadId
        }
    };
    const findCategoryByExtension = (extension) => {
        for (const [category, extensions] of Object.entries(fileExtensions)) {
            if (extensions.includes(extension)) {
                return category;
            }
        }
        return 'document';
    };
    return (
        <Stack>


            <List sx={{ overflow: 'auto' }}>
                {searchType === "researcher" && (
                    results.length > 0 ? (
                        results.map((result) => (
                            <ListItem key={result.id} component={Link} to={`/researcher/${result.id}`}>
                                <ListItemAvatar>
                                    <Avatar src={result.imageUrl || fallbackImage} alt={result.name} />
                                </ListItemAvatar>
                                <ListItemText primary={`${result.firstname} ${result.lastname}`} secondary={`ID: ${result.id}`} />
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText primary="No researchers found." />
                        </ListItem>
                    )
                )}
                {searchType === "publication" && (
                    results.length > 0 ? (
                        results.map((result) => (
                            <ListItem key={result.id} component={Link}
                                to={`/publication/${result.slug}`}
                                style={{
                                    color: "#091582",
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                                onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                            >

                                <ListItemIcon>
                                    <ArticleIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={result.title}
                                    secondary={result.summary}
                                />
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText primary="No publications found." />
                        </ListItem>
                    )
                )}
                {searchType === "rawData" && (
                    results.length > 0 ? (
                        results.map((result) => (
                            <Stack key={result.id}>
                                <Stack direction="row" spacing={1} mb={2} alignItems="center">
                                    <ListItemIcon sx={{ width: 40, height: 40 }}>
                                        <FileIcon
                                            extension={result.rawDataEx}
                                            color={theme.palette.primary.main}
                                            foldColor={theme.palette.secondary.main}
                                            glyphColor="white"
                                            type={findCategoryByExtension(`.${result.rawDataEx}`)}
                                        />
                                    </ListItemIcon>


                                    <ListItemText
                                        primary={result.name}
                                        secondary={
                                            <Link
                                                to={`/publication/${result.slug}?rawDataType=${encodeURIComponent(result.name)}`}
                                                style={{
                                                    color: "#091582",
                                                    fontWeight: 'bold',
                                                    textDecoration: 'none',
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                                                onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}>{result.title}</Link>}
                                    />

                                </Stack>

                                {/* Display price and file name */}
                                <Stack spacing={1} mb={1}>
                                    <Typography variant="body2" color="success">
                                        Raw data count: {result.rawDataCount}
                                    </Typography>
                                </Stack>

                                {result.priceSuggestion === 0 && (
                                    <Stack direction="row" justifyContent="end" width="100%" mt={1}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                if (result.priceSuggestion === 0 && downloadId !== result.id) {
                                                    handleDownload(result.id, result.rawDataName);
                                                }
                                            }}
                                            disabled={downloadId === result.id} // Disable button only for the specific item being downloaded
                                        >
                                            {downloadId === result.id ? (
                                                `${progress}%` // Show progress for the specific item
                                            ) : (
                                                <>
                                                    {result.priceSuggestion !== 0 ? (
                                                        <ShoppingBasket />
                                                    ) : (
                                                        <Download />
                                                    )}
                                                    {downloadId === result.id && <span>Downloading...</span>}
                                                </>
                                            )}
                                        </Button>
                                        {downloadId === result.id && (
                                            <LinearProgress
                                                variant="determinate"
                                                value={progress}
                                                style={{ marginTop: "8px" }}
                                            />
                                        )}
                                    </Stack>
                                )}
                                <Divider sx={{ m: 1 }} />
                            </Stack>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText primary="No raw data found." />
                        </ListItem>
                    )
                )}




            </List >

        </Stack>
    );
};

export default SearchResults;
