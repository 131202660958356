import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, IconButton, Snackbar, Alert, Typography, Box, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon, FormatQuote, QuestionAnswer } from '@mui/icons-material';  // Importing the Material UI icon for the copy button

const baseUrl = process.env.REACT_APP_BASE_URL;

function CiteDialog({ publicationId, open, onClose }) {
  const [cite, setCite] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchCitation = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/v1/no-auth/publication/get-summary`, {
          params: { publicationId }  // Pass publicationId as a query parameter
        });
        setCite(response.data);  // Assuming 'citation' is returned in the response
      } catch (error) {
        console.error('Error fetching citation:', error);
      } finally {
        setLoading(false);
      }
    };

    if (publicationId && open) {
      fetchCitation();
    }
  }, [publicationId, open]);

  const handleCopy = () => {
    navigator.clipboard.writeText(cite).then(() => {
      setSnackbarMessage("Citation copied to clipboard!");
      setOpenSnackbar(true);
    }).catch((error) => {
      console.error("Failed to copy: ", error);
      setSnackbarMessage("Failed to copy citation.");
      setOpenSnackbar(true);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ color: 'primary.main', variant: 'h5', alignItems:"center" }}><FormatQuote sx={{rotate:"180deg"}}/> Cite this article:</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1" color="textPrimary" sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {cite}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>

        {/* Tooltip added to make the button more intuitive */}
        <Tooltip title="Copy citation to clipboard" arrow>
          <IconButton onClick={handleCopy} color="primary" disabled={loading} sx={{ fontSize: 36, padding: 2 }}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      </DialogActions>

      {/* Snackbar for showing copy status */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default CiteDialog;
