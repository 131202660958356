import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

// Helper function to create headers with the token
const getAuthHeaders = (token) => {
    return {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json',
    };
};

// Follow a user
export const followUser = async (followedId, token) => {
    try {
        const response = await axios.post(
            `${baseUrl}/api/v1/follow/follow`,
            null, // No body required
            {
                headers: getAuthHeaders(token),
                params: { followedId },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error following user:', error.response?.data || error.message);
        throw error;
    }
};

// Unfollow a user
export const unfollowUser = async (followedId, token) => {
    try {
        const response = await axios.post(
            `${baseUrl}/api/v1/follow/unfollow`,
            null,
            {
                headers: getAuthHeaders(token),
                params: { followedId },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error unfollowing user:', error.response?.data || error.message);
        throw error;
    }
};

// Check if a user is followed
export const checkIsFollowing = async (followedId, token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/follow/isFollowing`, {
            headers: getAuthHeaders(token),
            params: { followedId },
        });
        return response.data;
    } catch (error) {
        console.error('Error checking if user is following:', error.response?.data || error.message);
        throw error;
    }
};

// Get count of followers
export const getFollowerCount = async (userId, token) => {

    const url = token ? `${baseUrl}/api/v1/follow/followers/count` : `${baseUrl}/api/v1/no-auth/follow/followers/count`
    try {
        const response = await axios.get(url, {
            headers: getAuthHeaders(token),
            params: { userId },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching follower count:', error.response?.data || error.message);
        throw error;
    }
};

// Get count of following users
export const getFollowingCount = async (userId, token) => {
    const url = token ? `${baseUrl}/api/v1/follow/following/count` : `${baseUrl}/api/v1/no-auth/follow/following/count`
    try {
        const response = await axios.get(url, {
            headers: getAuthHeaders(token),
            params: { userId },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching following count:', error.response?.data || error.message);
        throw error;
    }
};

// Get list of followers
export const getFollowers = async (userId, token, page = 0) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/follow/followers`, {
            headers: getAuthHeaders(token),
            params: {
                userId,
                page, // Page number

            },
        });
        return response.data; // Ensure backend provides pageable data (e.g., content, totalPages, totalElements, etc.)
    } catch (error) {
        console.error('Error fetching followers:', error.response?.data || error.message);
        throw error;
    }
};

export const getFollowing = async (userId, token, page = 0) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/follow/following`, {
            headers: getAuthHeaders(token),
            params: {
                userId,
                page, // Page number

            },
        });
        return response.data; // Ensure backend provides pageable data (e.g., content, totalPages, totalElements, etc.)
    } catch (error) {
        console.error('Error fetching following users:', error.response?.data || error.message);
        throw error;
    }
};
