import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import CiteDialog from './CiteDialog';
import { FormatQuote } from '@mui/icons-material';

const CiteChip = ({ publicationId }) => {
    const [openCiteDialog, setOpenCiteDialog] = useState(false);
    const handleOpenCiteDialog = () => setOpenCiteDialog(true);
    const handleCloseCiteDialog = () => setOpenCiteDialog(false);

    return (
        <>
            <IconButton
                size='small'
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'primary.main', // Set the background color to primary
                    color: 'white', // Set the icon color to white
                    '&:hover': {
                        backgroundColor: 'primary.dark', // Darken the button color on hover
                    }
                }}
                onClick={handleOpenCiteDialog} // Open the Cite Dialog
                color="primary"
            >
                <FormatQuote sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <CiteDialog
                publicationId={publicationId} // Pass publicationId as a prop to the CiteDialog
                open={openCiteDialog}
                onClose={handleCloseCiteDialog}
            />
        </>
    );
};

export default CiteChip;
