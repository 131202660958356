import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';

const ResponsiveBreadcrumbs = ({ item }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Utility function to shorten text
  const shortenText = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
      <Breadcrumbs
        maxItems={2}
        aria-label="breadcrumb"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: isSmallScreen ? '0.8rem' : '1rem',
        }}
      >
        <Link
          underline="hover"
          color="inherit"
          href={`/publication/${item.slug}`}
          sx={{
            maxWidth: isSmallScreen ? '80px' : '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {shortenText(item.title, isSmallScreen ? 10 : 20)}
        </Link>
        {item.rawDataType && (
          <Link
            underline="hover"
            color="inherit"
            href={`/publication/${item.slug}?rawDataType=${item.rawDataType}`}
            sx={{
              maxWidth: isSmallScreen ? '80px' : '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {shortenText(item.rawDataType, isSmallScreen ? 8 : 15)}
          </Link>
        )}
        {item.rawData && (
          <Typography
            sx={{
              color: 'text.primary',
              maxWidth: isSmallScreen ? '80px' : '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {shortenText(item.rawData.name, isSmallScreen ? 12 : 20)}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default ResponsiveBreadcrumbs;
