import { PersonAdd, PersonRemove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Button, Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { followUser, unfollowUser, getFollowerCount, getFollowingCount, checkIsFollowing, getFollowers, getFollowing } from '../../services/followServices';
import PostShareByUser from '../publicationPosts/PostShareByUser';
import { useUserContext } from '../../auth/AuthProvider';

const FollowComponent = ({ userId, token }) => {
    const [isFollowing, setIsFollowing] = useState(false);
    const [followersCount, setFollowersCount] = useState(null);
    const [followingCount, setFollowingCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [type, setType] = useState(""); // Current dialog type
    const { user, authenticated } = useUserContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [followers, following, isUserFollowing] = await Promise.all([
                    getFollowerCount(userId, token),
                    getFollowingCount(userId, token),
                    token ? checkIsFollowing(userId, token) : Promise.resolve(false)
                ]);

                setFollowersCount(followers);
                setFollowingCount(following);
                setIsFollowing(isUserFollowing);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setDataLoading(false);
            }
        };

        fetchData();
    }, [userId, token]);

    const handleFollowToggle = async () => {
        setLoading(true);
        try {
            if (isFollowing) {
                await unfollowUser(userId, token);
                setFollowersCount((prev) => prev - 1);
            } else {
                await followUser(userId, token);
                setFollowersCount((prev) => prev + 1);
            }
            setIsFollowing((prev) => !prev);
        } catch (error) {
            console.error('Error toggling follow state:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUsers = async (currentType, pageNumber = 1) => {
        setDataLoading(true);
        try {
            const response = currentType === 'followers'
                ? await getFollowers(userId, token, pageNumber - 1)
                : await getFollowing(userId, token, pageNumber - 1);

            setUsersList(response.content);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Error fetching user list:', error);
        } finally {
            setDataLoading(false);
        }
    };

    const handleDialogOpen = (currentType) => {
        setType(currentType);
        setDialogTitle(currentType === 'followers' ? 'Followers' : 'Following');
        setPage(1); // Reset to the first page
        fetchUsers(currentType, 1);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setUsersList([]);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchUsers(type, value);
    };

    return (
        <Stack p={2} spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Stack alignItems="center" onClick={() => handleDialogOpen('followers')} sx={{
                    cursor: 'pointer', ":hover": { textDecoration: "none" }, textDecoration: "underline"
                }}>
                    <Typography>Followers</Typography>
                    {dataLoading ? (
                        <Skeleton variant="text" width={40} height={30} />
                    ) : (
                        <Typography>{followersCount}</Typography>
                    )}
                </Stack>
                <Stack alignItems="center" onClick={() => handleDialogOpen('following')} sx={{
                    cursor: 'pointer', ":hover": { textDecoration: "none" }, textDecoration: "underline"
                }}>
                    <Typography>Following</Typography>
                    {dataLoading ? (
                        <Skeleton variant="text" width={40} height={30} />
                    ) : (
                        <Typography>{followingCount}</Typography>
                    )}
                </Stack>
            </Stack>
            <Stack>
                {dataLoading ? (
                    <Skeleton variant="rectangular" width={150} height={50} />
                ) : (
                    authenticated && (user.id !== userId && <LoadingButton
                        color={isFollowing ? "error" : "primary"}
                        size="large"
                        variant="contained"
                        startIcon={isFollowing ? <PersonRemove /> : <PersonAdd />}
                        onClick={handleFollowToggle}
                        loading={loading}
                    >
                        {isFollowing ? "Unfollow" : "Follow"}
                    </LoadingButton>)
                )}
            </Stack>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {dataLoading ? (
                        <>
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                        </>

                    ) : usersList.length > 0 ? (
                        usersList.map((user, index) => (
                            <div key={index}>
                                <PostShareByUser shareByAuthor={user} />
                            </div>
                        ))
                    ) : (
                        <Typography>No users found.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button onClick={handleDialogClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default FollowComponent;
