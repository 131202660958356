import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Stack } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';
import { DeveloperMode, Explore, Insights, NewReleases, Person, Schema } from '@mui/icons-material';

function FilterMenu() {
    const { user } = useUserContext()
    const location = useLocation();
    return (
        <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px' }}>
            <List>
                <Link style={{ textDecoration: "none" }} to={`/`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <Explore />
                        </ListItemIcon>
                        <ListItemText primary="Explore" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/preprints`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <Schema  />
                        </ListItemIcon>
                        <ListItemText primary="Preprints" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/followed-researchers`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Followed Researchers' Publications" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={"/invitation"}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <MailOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Mail Box" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/library`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <LibraryBooksIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Stack direction="row" alignItems="center" spacing={2}>Library</Stack>} primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/notification`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <NotificationsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Notifications" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/researcher/${user.id}`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/search`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary="Search" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>
                {location.pathname == '/preprints' && <Link style={{ textDecoration: "none" }} to={`/preprint-2025`}>
                    <ListItem button>
                        <ListItemIcon sx={{ color: 'primary.main' }}>
                            <NewReleases />
                        </ListItemIcon>
                        <ListItemText primary="Preprint 2025" primaryTypographyProps={{ sx: { color: 'primary.main' } }} />
                    </ListItem>
                </Link>}
            </List>
        </Paper>
    );
}

export default FilterMenu;
