import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    CircularProgress,
    Button,
    Stack,
    Avatar,
    Skeleton,
} from '@mui/material';
import { getCoAuthorLastPublications } from '../../services/publicationService';
import { useUserContext } from '../../auth/AuthProvider';
import { Link, useParams } from 'react-router-dom';
import { getProfileImage } from '../../services/imageServices';
import FollowComponent from './FollowComponent';

const fallbackImage = 'path_to_fallback_image'; // Update with the actual fallback image path

const CoAuthor = () => {
    const { token, user, authenticated } = useUserContext();
    const { userId } = useParams();
    const [publications, setPublications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const itemsPerPage = 6; // Number of items per page

    useEffect(() => {
        const fetchPublications = async () => {
            setLoading(true);
            try {
                const response = await getCoAuthorLastPublications(
                    token,
                    userId,
                    currentPage,
                    itemsPerPage
                );
                console.log(response);

                setPublications(response.content || []);
                setTotalPages(response.totalPages || 0);
                setTotalElements(response.totalElements || 0);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPublications();
    }, [userId, token, currentPage]);

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    if (loading) {
        return (
            <Paper sx={{ p: 3, borderRadius: 5 }}>
                <Typography color="primary.main" variant="h6" gutterBottom>
                    Loading Co-Authors Publications...
                </Typography>
                <Grid container spacing={2}>
                    {Array.from({ length: itemsPerPage }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Paper elevation={5} style={{ padding: 10, borderRadius: 5 }}>
                                <Stack alignItems="center">
                                    <Skeleton
                                        variant="circular"
                                        width={50}
                                        height={50}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width="60%"
                                        height={20}
                                        sx={{ mt: 1 }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width="80%"
                                        height={20}
                                        sx={{ mt: 0.5 }}
                                    />
                                </Stack>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        );
    }

    if (error || publications.length === 0) {
        return (
            <Paper sx={{ p: 3, borderRadius: 5 }}>
                <Typography color="text.secondary" variant="h6" align="center">
                    We haven't found any co-author for you yet.
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper sx={{ p: 3, borderRadius: 5 }}>
            <Typography color="primary.main" variant="h6" gutterBottom>
                Recent Publications of Your Co-Authors ({totalElements})
            </Typography>
            <Grid container spacing={2}>
                {publications.map((publication) => (
                    <Grid item xs={12} sm={6} md={6} key={publication.id}>
                        <Paper elevation={5} style={{ padding: 10, borderRadius: 5 }}>
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={`/researcher/${publication.id}`}
                            >
                                <Stack alignItems="center">
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Avatar
                                            sx={{ width: 50, height: 50 }}
                                            alt={`${publication.firstname} ${publication.lastname}`}
                                            src={publication.imageUrl || fallbackImage}
                                        />
                                    </Stack>
                                    <Typography variant="subtitle1">
                                        {publication.firstname} {publication.lastname}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="gray"
                                        sx={{ opacity: 0.8 }}
                                    >
                                        {publication.university ? publication.university : ''}
                                    </Typography>
                                </Stack>
                            </Link>
                            {authenticated && user.id !== publication.id && (
                                <FollowComponent token={token} userId={publication.id} />
                            )}
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                    onClick={handlePrev}
                    disabled={currentPage === 0}
                    variant="contained"
                >
                    Previous
                </Button>
                <Button
                    onClick={handleNext}
                    disabled={currentPage === totalPages - 1}
                    variant="contained"
                >
                    Next
                </Button>
            </Box>
        </Paper>
    );
};

export default CoAuthor;
