import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Post from './Post';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Box, Paper, Skeleton, Stack, useMediaQuery } from '@mui/material';
import { Autoplay, Pagination } from 'swiper/modules';

const baseUrl = process.env.REACT_APP_BASE_URL;

const MainPagePost = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)'); // Mobil ekran tespiti
    const [currentIndex, setCurrentIndex] = useState(0); // Postlar arasında geçiş için index

    const fetchPublication = async (title) => {
        try {
            const response = await axios.get(`${baseUrl}/api/v1/no-auth/publication/get-publication-page/${title}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching publication (${title}):`, error);
            return null; // Başarısız isteklere null döndür
        }
    };



    // Postları fetch et
    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            setError(null);

            const publicationTitles = [
                "Effect of corrosion on flexural strength of reinforced concrete beams with polypropylene fibers",
                "Experimental evaluation and modeling of corroded reinforced concrete columns V3",
                "Numerical investigation of precast reinforced concrete beam–to–column joints by replaceable damper"
            ];

            try {
                const encodedTitles = publicationTitles.map(title =>
                    encodeURIComponent(title).replace(/-/g, '_').replace(/%20/g, '-')
                );

                const postPromises = encodedTitles.map(fetchPublication);
                const results = await Promise.all(postPromises);

                const successfulPosts = results.filter(post => post !== null);
                setPosts(successfulPosts);
            } catch (err) {
                console.error("Unexpected error while fetching posts:", err);
                setError("Failed to load posts.");
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);
    // 3 saniyede bir postları değiştir
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % posts.length); // Postları sırayla değiştir
        }, 3000); // 3 saniye arayla

        return () => clearInterval(timer); // Temizleme işlemi
    }, [posts]); // posts değiştiğinde tekrar çalışacak
    // Yükleniyor durumu
    if (loading) {
        return (
            <Paper sx={{ height: 550, width: "96%", padding: 2, borderRadius: 3 }}>
                <Stack spacing={2}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            height={index === 0 ? 118 : 38}
                            width={index === 1 ? 700 : 400}
                            sx={{ mb: 1, borderRadius: 3 }}
                        />
                    ))}
                    <Stack direction="row" spacing={1}>
                        {Array.from({ length: 6 }).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant="rectangular"
                                height={28}
                                width={100}
                                sx={{ mb: 1, borderRadius: 3 }}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Paper>
        );
    }

    // Hata durumu
    if (error) {
        return (
            <Paper sx={{ padding: 3, textAlign: 'center' }}>
                An error occurred: {error.message || "Something went wrong. Please try again."}
            </Paper>
        );
    }

    // Mobilde sadece bir post göstermek ve geçiş yapmak
    if (isMobile) {
        return (
            <Box sx={{ width: '100%' }}>
                <Box mb={2}>
                    {posts.length > 0 && (
                        <Post publicStatus={true} data={posts[currentIndex]} />
                    )}
                </Box>
            </Box>
        );
    }

    // Masaüstü için Swiper kullanımı
    return (
        <Swiper
            modules={[Autoplay, Pagination]}
            spaceBetween={30}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
            {posts.map((post, index) => (
                <SwiperSlide key={index}>
                    <Box borderRadius={5} height={700} width="100%">
                        <Post publicStatus={true} data={post} />
                    </Box>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default MainPagePost;
