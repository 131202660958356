import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    Stack,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
    ButtonGroup,
    Alert,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Add, Cancel, Delete, Publish, Save } from "@mui/icons-material";
import { deleteComment, getComment, saveComment, updateComment } from '../services/commentServices'
import { useUserContext } from "../auth/AuthProvider";
import { LoadingButton } from "@mui/lab";
const CommentUpdatePage = () => {
    const [alertMessage, setAlertMessage] = useState("");
    const { token } = useUserContext()
    const { commentId } = useParams();
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [formData, setFormData] = useState({
        saveAsDraft: false,
        nameInSharedReview: false,
        generalComment: "",
        recommendedJournal: "",
        pageLines: [],
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchPublication = async () => {
            try {
                const data = await getComment(token, commentId);
                setFormData(data)
            } catch (error) {

            } finally {
                setLoading(false);
            }
        };

        fetchPublication()
    }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCommentChange = (index, event) => {
        const { name, value } = event.target;
        setFormData((prev) => {
            const updatedComments = [...prev.pageLines];
            updatedComments[index][name] = value;
            return { ...prev, pageLines: updatedComments };
        });
    };

    const addComment = () => {
        setFormData((prev) => ({
            ...prev,
            pageLines: [
                ...prev.pageLines,
                {
                    id: prev.pageLines.length + 1,
                    content: "",
                    pageNumber: "",
                    lineNumber: "",
                },
            ],
        }));
    };

    const removeComment = (index) => {
        setFormData((prev) => ({
            ...prev,
            pageLines: prev.pageLines.filter((_, i) => i !== index),
        }));
    };

    const handleCancel = () => {
        navigate(-1);
    }
    const handleDelete = async (commentId) => {
        setDeleteLoading(true)
        try {
            await deleteComment(token, commentId); // Call the service to delete the comment
            navigate(-1)
        } catch (error) {
            console.error("Error deleting comment:", error);
        } finally {
            setDeleteLoading(false)
        }
    }
    const handleSubmit = async (event, isDraft) => {
        event.preventDefault();
        // If it's not a draft, validate the form
        if (!isDraft) {
            if (
                !formData.generalComment ||
                !formData.recommendedJournal ||
                formData.pageLines.some(comment =>
                    !comment.content || !comment.pageNumber || !comment.lineNumber
                )
            ) {
                setAlertMessage("Please fill out all required fields before publishing.");
                return;
            }
        }
        const updatedFormData = {
            ...formData,
            saveAsDraft: isDraft,  // Update only saveAsDraft property
        };

        try {
            await updateComment(token, commentId, updatedFormData);
            navigate(-1);
        } catch (error) {
            console.error("Error updating comment:", error);
        }
    };
    if (loading) {
        return (
            <Container sx={{ mt: 2 }}>
                <Paper sx={{ padding: 2, textAlign: "center" }}>
                    <CircularProgress />
                    <Typography>Loading...</Typography>
                </Paper>
            </Container>
        );
    }
    return (
        <Container maxWidth="md" sx={{ mt: 3 }}>
            {alertMessage && (
                <Alert severity="error" onClose={() => setAlertMessage("")}>
                    {alertMessage}
                </Alert>
            )}
            <Paper sx={{ p: 4 }} elevation={4}>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Update Comment
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {/* Render dynamic list of comments */}
                        {formData.pageLines.map((commentData, index) => (
                            <Stack
                                key={commentData.id}
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Stack spacing={1}>
                                    <TextField
                                        label="Page Number"
                                        size="small"
                                        name="pageNumber"
                                        type="number"
                                        value={commentData.pageNumber}
                                        onChange={(e) => handleCommentChange(index, e)}
                                        required
                                        sx={{ flex: 1 }}
                                    />
                                    <TextField
                                        label="Line Number"
                                        size="small"
                                        name="lineNumber"
                                        type="number"
                                        value={commentData.lineNumber}
                                        onChange={(e) => handleCommentChange(index, e)}
                                        required
                                        sx={{ flex: 1 }}
                                    />
                                </Stack>

                                <Stack width="100%">
                                    <TextField
                                        label="Comment"
                                        name="content"
                                        value={commentData.content}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 5000) handleCommentChange(index, e);
                                        }}
                                        fullWidth
                                        multiline
                                        size="small"
                                        rows={4}
                                        required
                                    />
                                    <Typography variant="caption" color={commentData.content.length > 5000 ? 'error' : 'textSecondary'}>
                                        {commentData.content.length} / 5000 characters
                                    </Typography>
                                </Stack>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => removeComment(index)}
                                >
                                    Remove
                                </Button>
                            </Stack>
                        ))}

                        <Button
                            size="small"
                            startIcon={<Add />}
                            variant="outlined"
                            onClick={addComment}
                            disabled={formData.pageLines.some(comment =>
                                !comment.content || !comment.pageNumber || !comment.lineNumber
                            )}
                        >
                            Add Comment
                        </Button>
                        <Stack>
                            <TextField
                                label="General Comment"
                                name="generalComment"
                                value={formData.generalComment}
                                onChange={(e) => {
                                    if (e.target.value.length <= 5000) handleInputChange(e);
                                }}
                                fullWidth
                                multiline
                                size="small"
                                rows={4}
                                required
                            />
                            <Typography variant="caption" color={formData.generalComment.length > 5000 ? 'error' : 'textSecondary'}>
                                {formData.generalComment.length} / 5000 characters
                            </Typography>
                        </Stack>

                        <Typography>
                            Which journal would you recommend for authors to submit their
                            study?
                        </Typography>
                        <TextField
                            name="recommendedJournal"
                            value={formData.recommendedJournal}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            required
                        />
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                                Do you want your name to appear in the shared review?
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={formData.nameInSharedReview}
                                onChange={handleInputChange}
                                name="nameInSharedReview"
                                row
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="No"
                                />
                            </RadioGroup>
                        </FormControl>

                        <Stack textAlign="right" spacing={1} direction="row" alignItems="center" justifyContent="end">
                            <ButtonGroup sx={{ borderRadius: 3 }}>
                                <Button
                                    startIcon={<Cancel />}
                                    variant="outlined"
                                    color="error"
                                    onClick={handleCancel}
                                    type="button" // Changed to 'button' since it's for canceling
                                >
                                    Cancel Update
                                </Button>
                                <LoadingButton
                                    startIcon={<Delete />}
                                    variant="outlined"
                                    color="error"
                                    onClick={handleDelete}
                                    type="button" // Changed to 'button' since it's for deleting
                                >
                                    Delete Comment
                                </LoadingButton>
                                <Button
                                    startIcon={<Save />}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(event) => {
                                        setFormData((prev) => ({ ...prev, saveAsDraft: true }));
                                        handleSubmit(event, true); // Save as draft
                                    }}
                                >
                                    Update Save As Draft
                                </Button>
                                <Button
                                    startIcon={<Publish />}
                                    variant="contained"
                                    color="primary"
                                    onClick={(event) => {
                                        setFormData((prev) => ({ ...prev, saveAsDraft: false }));
                                        handleSubmit(event, false); // Publish review
                                    }}
                                    type="submit"
                                >
                                    Update Review
                                </Button>
                            </ButtonGroup>

                        </Stack>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
};

export default CommentUpdatePage;
