import React, { useEffect, useState } from 'react';
import { Grid, Typography, Stack, Box, Tooltip, Paper, Button, Dialog, IconButton, LinearProgress } from '@mui/material';
import { FileIcon } from 'react-file-icon';
import { useTheme } from '@emotion/react';
import { fileExtensions } from './fileExtentionType';
import { getRawDataImage } from '../../services/imageServices';
import CloseIcon from '@mui/icons-material/Close';
import { PostComment } from './PostComment';
import { Download, NoAccounts, RemoveShoppingCart, ShoppingBasket, ShoppingBasketSharp, ShoppingCart, ZoomIn } from '@mui/icons-material';
import axios from 'axios';
import { useUserContext } from '../../auth/AuthProvider';
import { LoadingButton } from '@mui/lab';
const baseUrl = process.env.REACT_APP_BASE_URL;
const truncateName = (name, maxLength) => {
  if (name.length > maxLength) {
    return name.substring(0, maxLength) + '...';
  }
  return name;
};

const PostRawDataDetail = ({ rawData, onDataCountChange }) => {
  const maxLength = 16;
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [downloadId, setDownloadId] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [hoveredImageId, setHoveredImageId] = useState(null); // Track which image is being hovered over
  const { token } = useUserContext()
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Yüzdelik ilerleme durumu

  const handleDownload = async (id, dataName) => {
    try {
      setDownloadId(id);
      setLoading(true); // Start loading state
      setProgress(0); // Reset progress
      console.log("Data Id", id);
      // Make the request to get the S3 link
      const response = await axios.get(`${baseUrl}/api/v1/file/raw-data/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Use the returned S3 URL directly
      const s3Url = response.data; // Assume the S3 link is in `s3Link` property
      const anchor = document.createElement("a");
      anchor.href = s3Url;
      anchor.setAttribute("download", dataName); // Use the provided file name
      anchor.click();
    } catch (error) {
      console.error("File could not be downloaded:", error);
    } finally {
      setLoading(false); // Stop loading state
      setProgress(0); // Reset progress
      setDownloadId(null); // Reset downloadId
    }
  };
  const addChart = async (dataId) => {
    try {
      const response = await axios.post(`${baseUrl}/api/v1/carts/add/${dataId}`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Check for successful response
      if (response.status === 200) {
        console.log("Added to chart:", response.data);
      } else {
        console.warn("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Failed to add to chart:", error.response ? error.response.data : error.message);
    }
  };
  const findCategoryByExtension = (extension) => {
    for (const [category, extensions] of Object.entries(fileExtensions)) {
      if (extensions.includes(extension)) {
        return category;
      }
    }
    return 'document';
  };

  useEffect(() => {
    if (onDataCountChange) {
      onDataCountChange(rawData.length);
    }
  }, [rawData]);

  // Fetch image and store it as a blob URL


  // Fetch images when rawData changes


  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const DownloadButton = ({ data }) => {
    return (<div style={{ position: "relative", width: "100%" }}>
      <Button
        variant="contained"
        onClick={() => {
          if (data.priceSuggestion == 0 && downloadId !== data.id) {
            handleDownload(data.id, data.rawDataName);
          }
        }}
        disabled={downloadId === data.id} // Disable button only for the specific item being downloaded
        style={{ width: "100%" }}
      >
        {downloadId === data.id ? (
          `${progress}%` // Show progress for the specific item
        ) : data.priceSuggestion !== 0 ? (
          <ShoppingBasket />
        ) : (
          <Download />
        )}
      </Button>
      {downloadId === data.id && (
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ marginTop: "8px" }}
        />
      )}
    </div>)
  }
  const BasketButton = ({ data, token, baseUrl }) => {
    const [isInCart, setIsInCart] = useState(data.cart && data.priceSuggestion !== 0); // Sepette olup olmadığını kontrol eden state
    const [isProcessing, setIsProcessing] = useState(false); // İşlem sırasında butonu devre dışı bırakma kontrolü

    const handleRemoveItem = async (rawDataId) => {
      try {
        setIsProcessing(true);
        await axios.delete(`${baseUrl}/api/v1/carts/delete-raw-data-id/${rawDataId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setIsInCart(false); // Sepetten çıkarıldığını belirt
      } catch (error) {
        console.error("Error removing item from cart:", error);
      } finally {
        setIsProcessing(false); // İşlem tamamlandı
      }
    };

    const handleAddToCart = async (rawDataId) => {
      try {
        setIsProcessing(true);
        await axios.post(
          `${baseUrl}/api/v1/carts/add/${rawDataId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsInCart(true); // Sepete eklendiğini belirt
      } catch (error) {
        console.error("Error adding item to cart:", error);
      } finally {
        setIsProcessing(false); // İşlem tamamlandı
      }
    };

    const handleCartAction = () => {
      if (isInCart) {
        handleRemoveItem(data.id); // Sepetteyse sil
      } else {
        handleAddToCart(data.id); // Sepette değilse ekle
      }
    };

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={handleCartAction}
          disabled={isProcessing} // İşlem sırasında butonu devre dışı bırak
          loading={isProcessing}
          sx={{
            backgroundColor: isInCart ? 'red' : '#091582'
          }}
        >
          {isInCart ? (
            <>
              <RemoveShoppingCart />

            </>
          ) : (
            <>
              <ShoppingCart />

            </>
          )}
        </LoadingButton>
      </div>
    );
  };
  return (
    <>
      <Grid container spacing={1} p={1}>
        {rawData.map((data) => (
          <Grid
            item
            md={3}
            sm={12}
            key={data.id}
            p={2}
            borderRadius={3}
            sx={{ ":hover": { bgcolor: "#e1e1e1", alignItems: "center" } }}
          >
            <Paper sx={{ p: 1, borderRadius: 3, alignItems: "center" }} elevation={3}>
              <Stack direction="row" p={1} spacing={1} alignItems="center" >
                <Box width={30} height={30}>
                  <FileIcon
                    extension={data.rawDataEx}
                    color={theme.palette.primary.main}
                    foldColor={theme.palette.secondary.main}
                    glyphColor="white"
                    type={findCategoryByExtension(`.${data.rawDataEx}`)}
                  />
                </Box>

                <Tooltip title={data.name}>
                  <Typography color="secondary" fontWeight="bold">
                    {truncateName(data.name, maxLength)}
                  </Typography>
                </Tooltip>
              </Stack>

              <Stack direction="row" spacing={1} p={1}>
                <Box
                  position="relative"
                  onMouseEnter={() => setHoveredImageId(data.id)} // Set hover state for the specific image
                  onMouseLeave={() => setHoveredImageId(null)} // Reset hover state
                >
                  <Box
                    width={"100%"}
                    height={200}
                    component="img"
                    src={data.rawDataPreviewImageUrl}
                    alt={data.name}
                    onClick={() => handleOpen(data.rawDataPreviewImageUrl)}
                    style={{
                      cursor: 'pointer',
                      filter: hoveredImageId === data.id ? 'brightness(50%)' : 'none', // Darkens only when hovered
                      transition: 'filter 0.3s ease',
                    }}
                  />

                  {/* Magnifying Glass Icon */}
                  {hoveredImageId === data.id && (
                    <Box
                      position="absolute"
                      top="30%"
                      left="30%"
                      transform="translate(-50%, -50%)"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1
                      }}
                    >
                      <IconButton onClick={() => handleOpen(data.rawDataPreviewImageUrl)}>
                        <ZoomIn sx={{ width: 100, height: 100, color: 'white' }} />
                      </IconButton>

                    </Box>
                  )}
                </Box>
              </Stack>

              {data.comment ? <PostComment variant="body1" maxLength={30} comment={data.comment} commentType='Comment' /> : <Typography variant="h4" mb={9}> </Typography>}
              <Paper elevation={3} sx={{ p: 1 }}>
                <Stack direction="row" spacing={1} justifyContent="space-around">
                  {data.priceSuggestion != 0 ? <Typography variant="h4">{data.priceSuggestion}$</Typography> : <Typography variant="h5" color="primary.main">Free </Typography>}
                  {data.priceSuggestion == 0 ? <DownloadButton data={data} /> : <BasketButton data={data} token={token} baseUrl={baseUrl} />}
                </Stack>
              </Paper>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Modal to display the full image */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <IconButton
          onClick={handleClose}
          edge="end"
          color="inherit"
          aria-label="close"
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Box>
      </Dialog>
    </>
  );
};

export default PostRawDataDetail;
