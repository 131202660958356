import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, deleteLoading }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
        <DialogContent>
            This action cannot be undone.
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancel
            </Button>
            <LoadingButton
                color="error"
                loading={deleteLoading}
                onClick={onConfirm}
                startIcon={<Delete />}
            >
                Confirm
            </LoadingButton>
        </DialogActions>
    </Dialog>
);
export default DeleteConfirmationDialog;