import React, { useEffect, useState } from "react";
import {
    Alert,
    CircularProgress,
    Container,
    Paper,
    Typography,
    List,
    Divider,
    Stack,
    Button,
    Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import CommentBox from "../preprint/CommentBox";
import { Add } from "@mui/icons-material";
import { deleteComment, getCommentDraft, getCommentPage } from "../../services/commentServices";
import { useUserContext } from "../../auth/AuthProvider";

const SaveAsDraftComment = () => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(0); // Total pages
    const { token } = useUserContext();

    useEffect(() => {
      
        const fetchComments = async () => {
            setLoading(true);
            try {
                const data = await getCommentDraft(token, page - 1); // API accepts 0-based page index
                setComments(data.content);
                setTotalPages(data.totalPages);
                console.log(data)
            } catch (err) {
                setError("Failed to fetch comments");
            } finally {
                setLoading(false);
            }
        };
        fetchComments();
    }, [page]);
    const handleDelete = async (commentId) => {
        try {
            await deleteComment(token, commentId); // Call the service to delete the comment
            setComments(prevComments => prevComments.filter(comment => comment.id !== commentId)); // Update the list after deletion
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };
    const handlePageChange = (event, value) => {
        setPage(value); // Update current page
    };

    if (loading) {
        return (
            <Container sx={{ mt: 2 }}>
                <Paper sx={{ padding: 2, textAlign: "center" }}>
                    <CircularProgress />
                    <Typography>Loading...</Typography>
                </Paper>
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ mt: 2 }}>
                <Paper sx={{ padding: 2, textAlign: "center" }}>
                    <Alert severity="error">{error}</Alert>
                </Paper>
            </Container>
        );
    }

    return (
        <Container sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Comments
                </Typography>
                
            </Stack>

            <Divider sx={{ my: 2 }} />

            <List>
                {comments.length > 0 ? (
                    comments.map((comment, index) => (
                        <CommentBox handleDelete={handleDelete} key={index} comment={comment} />
                    ))
                ) : (
                    <Typography>No comments available.</Typography>
                )}
            </List>

            {totalPages > 1 && (
                <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack>
            )}
        </Container>
    );
};

export default SaveAsDraftComment;
