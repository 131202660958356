import NewPublicationProvider from "../context/NewPublicationProvider";
import HomePage from "../pages/HomePage";
import InvitationPage from "../pages/middlePage/InvitationPage";
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/MainPage";
import ExplorePage from "../pages/middlePage/ExplorePage";
import NewPublicationPage from "../pages/NewPublicationPage";
import PublicationPrivatePage from "../pages/PublicationPrivatePage";
import PublicationPublicPage from "../pages/PublicationPublicPage";
import PublicationEditPage from "../pages/PublicationEditPage";
import SignUpPage from "../pages/SignUpPage";
import AboutUs from "../pages/AboutUsPage";
import WhatIsJournalPage from '../pages/WhatIsJournelPage'
import TermsPage from "../pages/TermsPage";
import AccuracyPage from '../pages/AccuracyPage'
import FaqPage from '../pages/FaqPage'
import ProfileMainPage from "../pages/ProfilePage";
import ProfilePublicPage from "../pages/ProfilePublicPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ProfilePage from '../pages/middlePage/ProfilePage'
import PublicationPage from '../pages/middlePage/PublicationPage'
import AffiliationPage from "../pages/middlePage/AffiliationPage";
import EducationPage from "../pages/middlePage/EducationPage";
import AffiliationMainPage from '../pages/AffiliationPage'
import EducationMainPage from '../pages/EducationPage'
import ResearchInterestPage from "../pages/middlePage/ResearchInterestPage";
import ResearchInterestMainPage from '../pages/ResearchInterestPage'
import AcademicExperiencesMainPage from '../pages/middlePage/AcademicExperiencesPage'
import AcademicAppointmentsPage from "../pages/AcademicAppointmentsPage";
import DirectedThesisPage from '../pages/DirectedThesisPage'
import WebOfScienceCategoriesPage from "../pages/WebOfScienceCategoriesPage";
import PrivacyPage from "../pages/PrivacyPage";
import SearchPage from "../pages/SearchPage";
import ThesisJuryMemberPage from "../pages/middlePage/ThesisJuryMemberPage";
import ThesisJuryMemberMainPage from "../pages/ThesisJuryMemberPage";
import AcademicDutiesMainPage from "../pages/AcademicDutiesPage";
import NotificationPage from "../pages/middlePage/NotificationPage";
import StudentProjectPage from "../pages/StudentProjectPage";
import StudentCompetitionPage from "../pages/StudentCompetitionPage";
import LibraryPage from "../pages/LibraryPage";
import FollowedPage from "../pages/middlePage/FollowedPage";
import PreprintPage from "../pages/middlePage/PreprintPage";
import PublicationList from "../pages/middlePage/PublicationList";
import CommentPage from "../pages/CommentPage";
import CommentNewPage from "../pages/CommentNewPage";
import CommentUpdatePage from "../pages/CommentUpdatePage";
import SaveAsDraftComment from "../components/library/SaveAsDraftComment";
import PublishComment from "../components/library/PublishComment";
import Preprint2025 from "../pages/Preprint2025";
import CartPage from '../pages/middlePage/CartPage';
import PayPage from "../pages/PayPage";
export const nav = [

    {
        path: "/pay",
        element: <PayPage />,
        isMenu: true,
        isPrivate: true,  
    },
    {
        path: "/",
        element: <MainPage />,
        isMenu: false,
        isPrivate: false,
    },
    {
        path: "/rdl/publication/:title",
        element: <PublicationPublicPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/forget-password",
        element: <ForgetPasswordPage />,
        isMenu: false,
        isPrivate: false,
    },
    {
        path: "/reset-password",
        element: <ResetPasswordPage />,
        isMenu: false,
        isPrivate: false,
    },
    {
        path: "/preprint-2025",
        element: <Preprint2025 />,
        isMenu: false,
        isPrivate: false,
    },
    {
        path: "/preprint-2025",
        element: <Preprint2025 />,
        isMenu: true,
        isPrivate: true,
    },
    {
        path: "/",
        element: <HomePage />,
        isMenu: true,
        isPrivate: true,
        children: [
            {
                path: "/",
                element: <ExplorePage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "/followed-researchers",
                element: <FollowedPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "/invitation",
                element: <InvitationPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "/notification",
                element: <NotificationPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "/search",
                element: <SearchPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "/preprints",
                element: <PreprintPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "/cart",
                element: <CartPage />,
                isMenu: true,
                isPrivate: true
            }

        ]

    },
    {
        path: "/library",
        element: <LibraryPage />,
        isMenu: true,
        isPrivate: true,
        children: [
            {
                path: "",
                element: <PublicationList />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "publish-comment",
                element: <PublishComment />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "save-as-draft",
                element: <SaveAsDraftComment />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "",
                element: <PublicationList />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "publication/:type",
                element: <PublicationList />,
                isMenu: true,
                isPrivate: true
            },
        ]
    },
    {
        path: "/researcher/:userId",
        element: <ProfileMainPage />,
        isMenu: true,
        isPrivate: true,
        children: [

            {
                path: "",
                element: <ProfilePage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "publications",
                element: <PublicationPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "affiliation",
                element: <AffiliationPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "education",
                element: <EducationPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "research-interest",
                element: <ResearchInterestPage />,
                isMenu: true,
                isPrivate: true
            },
            {
                path: "academic-experiences",
                element: <AcademicExperiencesMainPage />,
                isMenu: true,
                isPrivate: true
            },

        ]
    }, {
        path: "student-competitions-edit",
        element: <StudentCompetitionPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/comments/:publicationTitle",
        element: <CommentPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/comments/:preprintId/new",
        element: <CommentNewPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/comments/:commentId/update",
        element: <CommentUpdatePage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "student-projects-edit",
        element: <StudentProjectPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "academic-duties-edit",
        element: <AcademicDutiesMainPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "thesis-jury-members-edit",
        element: <ThesisJuryMemberMainPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/academic-appointments-edit",
        element: <AcademicAppointmentsPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/directed-thesis-edit",
        element: <DirectedThesisPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/affiliation-edit",
        element: <AffiliationMainPage />,
        isMenu: true,
        isPrivate: true,
    },
    {
        path: "/web-of-science-category-edit",
        element: <WebOfScienceCategoriesPage />,
        isMenu: true,
        isPrivate: true,
    },
    {
        path: "/education-edit",
        element: <EducationMainPage />,
        isMenu: true,
        isPrivate: true,
    },
    {
        path: "/research-interest-edit",
        element: <ResearchInterestMainPage />,
        isMenu: true,
        isPrivate: true,
    },
    {
        path: "/rdl/researcher/:userId",
        element: <ProfilePublicPage />,
        isMenu: false,
        isPrivate: false,
        children: [
            {
                path: "",
                element: <ProfilePage />,
                isMenu: false,
                isPrivate: false
            },
            {
                path: "publications",
                element: <PublicationPage />,
                isMenu: false,
                isPrivate: false
            },
            {
                path: "affiliation",
                element: <AffiliationPage />,
                isMenu: false,
                isPrivate: false
            },
            {
                path: "education",
                element: <EducationPage />,
                isMenu: false,
                isPrivate: false
            },
            {
                path: "research-interest",
                element: <ResearchInterestPage />,
                isMenu: false,
                isPrivate: false
            },
            {
                path: "academic-experiences",
                element: <AcademicExperiencesMainPage />,
                isMenu: false,
                isPrivate: false
            },
        ]
    },
    {
        path: "/publication/:title",
        element: <PublicationPrivatePage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/edit-publication/:publicationId",
        element: <PublicationEditPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/accuracy",
        element: <AccuracyPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/faq",
        element: <FaqPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/faq",
        element: <FaqPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/accuracy",
        element: <AccuracyPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/privacy",
        element: <PrivacyPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/privacy",
        element: <PrivacyPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/terms",
        element: <TermsPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/terms",
        element: <TermsPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/whatIs",
        element: <WhatIsJournalPage />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/whatIs",
        element: <WhatIsJournalPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/aboutUs",
        element: <AboutUs />,
        isMenu: true,
        isPrivate: true
    },
    {
        path: "/aboutUs",
        element: <AboutUs />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/login",
        element: <LoginPage />,
        isMenu: false,
        isPrivate: false
    },

    {
        path: "/signup",
        element: <SignUpPage />,
        isMenu: false,
        isPrivate: false
    },
    {
        path: "/new-publication",

        element: <NewPublicationProvider><NewPublicationPage /></NewPublicationProvider>,
        isMenu: true,
        isPrivate: true
    },



]


