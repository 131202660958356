import { Divider, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import PostRawDataDetail from './PostRawDataDetail';
import { scroller } from 'react-scroll';
import { useSearchParams } from 'react-router-dom';

const PostFolderDetail = ({ folders = [] }) => {
    const [searchParams] = useSearchParams();
    const rawDataType = searchParams.get('rawDataType');

    useEffect(() => {
        if (rawDataType) {
            setTimeout(() => {
                scroller.scrollTo(rawDataType, {
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                });
            }, 500); // Gecikmeyi veri yükleme durumuna göre ayarlayın
        }
    }, [rawDataType, folders]);

    if (folders.length === 0) {
        return <Typography variant="h6">No folders available</Typography>;
    }

    return (
        <Stack spacing={1}>
            <Typography>{rawDataType}</Typography>
            {folders.map(folder => (
                <Paper key={folder.id} sx={{border:rawDataType == folder.name ? "3px solid" : "0px solid",borderColor:rawDataType == folder.name ? "primary.main" : ""}}>
                    <div id={folder.name}>
                        <Stack padding={2}>
                            <Typography fontWeight="bold" variant="h6">
                                {folder.name}
                            </Typography>
                            <Divider />
                            <PostRawDataDetail rawData={folder.rawData} />
                        </Stack>
                    </div>
                </Paper>
            ))}
        </Stack>
    );
};

export default PostFolderDetail;
