import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ResponsiveBreadcrumbs from "./ResponsiveBreadcrumbs"; // Ensure this is correctly imported

const RawDataCart = ({ item, removingItemId, setSelectedImage, handleRemoveItem }) => {
  return (
    <Grid item xs={12} sm={6} md={12} key={item.id}>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          height: "100%",
          borderRadius: "16px",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
          overflow: "hidden",
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "scale(1.02)",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {/* Image Section */}
        <CardMedia
          component="img"
          image={item.rawData?.rawDataPreviewImageUrl || "/placeholder-image.jpg"}
          alt={item.rawData?.name || "No name"}
          sx={{
            width: { xs: "100%", sm: 200 },
            height: 200,
            objectFit: "cover",
            cursor: "pointer",
            transition: "opacity 0.3s",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          onClick={() => setSelectedImage(item.rawData?.rawDataPreviewImageUrl)}
        />

        {/* Content Section */}
        <CardContent sx={{ flexGrow: 1, padding: 2 }}>
          <ResponsiveBreadcrumbs item={item} />
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              textTransform: "capitalize",
              marginTop: { xs: 1, sm: 0 },
            }}
          >
            {item.rawData?.name || "No name available"}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              marginTop: 1,
              lineHeight: 1.6,
            }}
          >
            {item.rawData?.comment || "No comment"}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#004d40",
              marginTop: 2,
            }}
          >
            Price: ${item.rawData?.priceSuggestion || 0}
          </Typography>
        </CardContent>

        {/* Divider for Larger Screens */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: { xs: "none", sm: "block" },
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        />

        {/* Actions Section */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Remove item from cart">
            <IconButton
              color="error"
              onClick={() => handleRemoveItem(item.id)}
              disabled={removingItemId === item.id}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                },
              }}
            >
              {removingItemId === item.id ? (
                <CircularProgress size={24} />
              ) : (
                <DeleteIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    </Grid>
  );
};

export default RawDataCart;
