import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress,
  Skeleton,
  Pagination,
  IconButton,
  Tooltip,
  Stack,
  Paper,
  Container,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Breadcrumbs,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
import { useUserContext } from "../../auth/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { Close, Payment } from "@mui/icons-material";
import ResponsiveBreadcrumbs from "../../components/cart/ResponsiveBreadcrumbs";
import RawDataCart from "../../components/cart/RawDataCart";

const baseUrl = process.env.REACT_APP_BASE_URL;

const CartPage = () => {
  const [cartPage, setCartPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [removingItemId, setRemovingItemId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { token } = useUserContext();
  const navigate = useNavigate();

  const itemsPerPage = 6;

  useEffect(() => {
    const fetchCart = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}/api/v1/carts?page=${currentPage - 1}&size=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Data", response.data)
        setCartPage(response.data);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCart();
  }, [token, currentPage]);

  const handleRemoveItem = async (cartItemId) => {
    try {
      setRemovingItemId(cartItemId);
      await axios.delete(`${baseUrl}/api/v1/carts/delete/${cartItemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await axios.get(
        `${baseUrl}/api/v1/carts?page=${currentPage - 1}&size=${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCartPage(response.data);
    } catch (error) {
      console.error("Error removing item:", error);
    } finally {
      setRemovingItemId(null);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const calculateTotalPrice = () => {
    if (!cartPage.items) return 0;
    return cartPage.items.content
      .reduce((total, item) => total + (item.rawData?.priceSuggestion || 0), 0)
      .toFixed(2);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Container>
        <Typography variant="h4" gutterBottom sx={{ color: "primary.main" }}>
          <ShoppingCartIcon fontSize="large" sx={{ marginRight: 1 }} />
          Your Cart (Total Raw Data Items: {cartPage?.items.totalElements || 0})
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {loading ? (
          <Grid container spacing={3}>
            {Array.from({ length: itemsPerPage }).map((_, index) => (
              <Grid item xs={12} sm={6} md={12} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    borderRadius: "12px",
                  }}
                >
                  <Skeleton variant="rectangular" height={200} />
                  <CardContent>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="40%" />
                    <Skeleton variant="text" width="80%" sx={{ mt: 2 }} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : cartPage.items && cartPage.items.content.length === 0 ? (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Your cart is empty.
            </Typography>
            <Button
              variant="contained"
              startIcon={<HomeIcon />}
              onClick={() => navigate("/")}

            >
              Go to Home
            </Button>
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {cartPage.items.content.map((item, index) => (
                <RawDataCart setSelectedImage={setSelectedImage} handleRemoveItem={handleRemoveItem} key={index} item={item} removingItemId={removingItemId} />
              ))}
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Pagination
                count={cartPage.items.totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{ "& .MuiPaginationItem-root": { fontSize: "16px", fontWeight: "bold" } }}
              />
            </Box>
            <Paper elevation={5} sx={{ p: 3, mt: 4 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Total Price: ${cartPage.totalPrice}</Typography>
                <Link to="/pay">
                  <Button
                    startIcon={<Payment />}
                    variant="contained"
                    color="secondary"
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                    onClick={() => alert("Proceed to checkout")}
                  >
                    go to pay
                  </Button>
                </Link>

              </Stack>
            </Paper>
          </>
        )}
        <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)} maxWidth="md">
          <DialogTitle>
            <IconButton
              onClick={() => setSelectedImage(null)}

            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <img
              src={selectedImage}
              alt="Preview"
              style={{ width: "100%", height: "auto", borderRadius: "12px" }}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </Paper>
  );
};

export default CartPage;
