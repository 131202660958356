import React, { useEffect, useState } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { Notifications } from '@mui/icons-material';
import { Badge, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUserContext } from '../../auth/AuthProvider';

const baseUrl = process.env.REACT_APP_BASE_URL;
const wsEndpoint = `${baseUrl}/ws`;


const NotificationComponent = ({ userId }) => {
    const [notifications, setNotifications] = useState([]);
    const [stompClient, setStompClient] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const testBaglantiEndpoint = '/app/notification';
    const testSonucTopic = `/topic/${userId}/notification`;
    const {token} = useUserContext()


    const getAuthHeaders = (token) => {
        return token ? { Authorization: `Bearer ${token}` } : {};
    };
    const getNotifications = async () => {
 
        try {
            const response = await axios.get(`${baseUrl}/api/v1/notification/notifications/count`, {
                headers: getAuthHeaders(token),
            });
            setNotifications(response.data);
        } catch (error) {
            console.error('Error fetching notifications:', error.response?.data || error.message);
            setError(error.response?.data || error.message);
        } finally {
            
        }
    };
    useEffect(() => {
        
        const socket = new SockJS(wsEndpoint);
        const client = new Client({
            webSocketFactory: () => socket,
            debug: (str) => console.log(str),
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        });

        client.onConnect = () => {
            getNotifications()
            console.log('WebSocket bağlantısı kuruldu.');
            setStompClient(client);
            setIsConnected(true);
            setError(null);

            // Subscribe only after the connection is established
            client.subscribe(testSonucTopic, (message) => {
                console.log("Gelen mesaj:", message); // Log full message object
                try {
                    const newNotification = JSON.parse(message.body); // Always parse JSON
                    console.log("Gelen Bildirim:", newNotification); // Log the received notification
                    setNotifications(newNotification);
                } catch (parseError) {
                    console.error("Bildirim ayrıştırma hatası:", parseError, message.body);
                    setError("Bildirim ayrıştırma hatası.");
                }
            });

            // Publish the test message only after successful connection
            client.publish({
                destination: testBaglantiEndpoint,
                body: JSON.stringify({ message: 'İstemciden Test Mesajı' }),
            });
        };
       
        client.onWebSocketError = (error) => {
            handleConnectionError('WebSocket hatası', error);
        };

        client.onStompError = (frame) => {
            handleConnectionError('STOMP hatası', frame.headers.message);
        };

        client.onDisconnect = () => {
            console.log('WebSocket bağlantısı kesildi.');
            setIsConnected(false);
            setStompClient(null);
        };

        const handleConnectionError = (message, error) => {
            console.error(message + ':', error);
            setError(`${message}: ${error?.toString()}`);
            setIsConnected(false);
        };

        // Activate the client
        client.activate();

        // Clean up on unmount
        return () => {
            if (client.active) {
                client.deactivate();
            }
        };
    }, []);

    return (
        <Link to="/notification" style={{ textDecoration: 'none' }}>
            <Badge badgeContent={notifications} color="primary">
                <Notifications
                    sx={{ color: 'primary.main', fontSize: { xs: 20, sm: 24 } }}
                />
            </Badge>
            {error && (
                <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: 1, fontSize: 12 }}
                >
                    {error}
                </Typography>
            )}
            {!isConnected && !error && (
                <Typography
                    variant="body2"
                    color="warning.main"
                    sx={{ marginTop: 1, fontSize: 12 }}
                >
                    Bağlantı bekleniyor...
                </Typography>
            )}
        </Link>
    );
};

export default NotificationComponent;
