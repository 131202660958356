import React, { useState } from 'react';
import { AutoStories, Delete, Edit } from '@mui/icons-material';
import { Avatar, Chip, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, Stack } from '@mui/material';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

const CommentBox = ({ comment, handleDelete }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const formatRelativeTime = (dateString) => {
        const date = parseISO(dateString);
        return formatDistanceToNow(date, { addSuffix: true });
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        console.log(comment)
        handleDelete(comment.id); // Call the parent function to delete the comment
        setOpenDialog(false);
    };

    return (
        <Paper
            key={comment.id}
            sx={{
                padding: 2,
                marginBottom: 2,
                backgroundColor: "#f9f9f9",
                borderRadius: 2,
                boxShadow: 2,
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar src={comment.user?.imageUrl} />
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" fontWeight="bold">
                        {comment.user?.firstname && comment.user?.lastname
                            ? `${comment.user.firstname} ${comment.user.lastname}`
                            : "Reviewer"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {formatRelativeTime(comment.createdDate)}
                    </Typography>
                </Grid>
                {comment.myComment && (
                    <Stack direction="row">
                        <Link to={`/comments/${comment.id}/update`}>
                            <IconButton edge="end" color="primary" onClick={handleOpenDialog}>
                                <Edit />
                            </IconButton>
                        </Link>


                        <IconButton edge="end" color="error" onClick={handleOpenDialog}>
                            <Delete />
                        </IconButton>
                    </Stack>


                )}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Typography color="text.primary">
                {comment.generalComment}
            </Typography>
            <Chip
                label={`Recommended Journal: ${comment.recommendedJournal}`}
                size="small"
                sx={{ mt: 1, color: "primary.main" }}
            />
            <Typography color="primary.main" sx={{ mt: 2 }}>
                Page Lines:
            </Typography>
            <List disablePadding>
                {comment.pageLines.map((line) => (
                    <ListItem key={line.id} disablePadding>
                        <ListItemIcon>
                            <AutoStories sx={{ color: 'primary.main' }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={`Page ${line.pageNumber}, Line ${line.lineNumber}`}
                            secondary={line.content}
                        />
                    </ListItem>
                ))}
            </List>

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Are you sure you want to delete this comment?</DialogTitle>
                <DialogContent>
                    <Typography>
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper >
    );
};

export default CommentBox;
