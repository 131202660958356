import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import { useUserContext } from "../../auth/AuthProvider";
import { useNewPublicationContext } from "../../context/NewPublicationProvider";

const baseUrl = process.env.REACT_APP_BASE_URL;

const TitleValidationTextField = ({ title, setTitle}) => {
    const [loading, setLoading] = useState(false);
    const { token } = useUserContext();
    const { publication, updateData, step, textValidation, setTextValidation } = useNewPublicationContext();
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            const checkTitleUniqueness = async (value) => {
                if (!value.trim()) {
                    setTextValidation(null);
                    return;
                }
                try {
                    setLoading(true);
                    const response = await axios.post(
                        `${baseUrl}/api/v1/publication/check-title`,
                        { title: value },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setTextValidation(response.data.isUnique); // The API should return `isUnique`
                } catch (error) {
                    console.error("Error checking title uniqueness:", error);
                    setTextValidation(null);
                } finally {
                    setLoading(false);
                }
            };

            checkTitleUniqueness(title);
        }, 500); // Debounce delay (500ms)

        return () => clearTimeout(debounceTimeout);
    }, [publication.data.title, token, setTextValidation]);

    const handleChange = (event) => {
        setTitle(event);
    };

    return (
        <TextField
            size="small"
            label="Title"
            variant="outlined"
            value={publication.data.title}
            name="title"
            onChange={handleChange}
            fullWidth
            inputProps={{
                maxLength: 255,
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {loading && <CircularProgress size={24} />}
                        {!loading && textValidation === true && (
                            <CheckCircleIcon color="success" />
                        )}
                        {!loading && textValidation === false && (
                            <ErrorIcon color="error" />
                        )}
                    </InputAdornment>
                ),
            }}
            helperText={
                textValidation === false
                    ? "This title already exists. Please choose a different one to ensure uniqueness."
                    : textValidation === true
                        ? "Great! This title is unique and can be used."
                        : loading
                            ? "Checking the uniqueness of the title. Please wait..."
                            : publication.data.title.trim().length === 0
                                ? "Title cannot be empty. Please enter a valid title."
                                : "Type a title to check its uniqueness."
            }
            error={textValidation === false}
        />
    );
};

export default TitleValidationTextField;
