import React, { useEffect, useState } from 'react';
import { useNewPublicationContext } from '../../../context/NewPublicationProvider';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import { Article, Schema } from '@mui/icons-material';
import IndexSelector from '../IndexSelector';
import DoiGenerate from './DoiGenerate';
import MyPublicationSelect from '../MyPublicationSelect';
import TitleValidationTextField from '../TitleValidationTextField';
const languages = [
    "Arabic", "Abkhaz", "Afar", "Albanian", "Alsatian", "Amharic", "Armenian",
    "Assamese", "Aymara", "Azari", "Azerbaijani", "Azerbaijani Turkic",
    "Bahasa Indonesia", "Bahasa Indonesian", "Balochi", "Bangla", "Bantu",
    "Bapounou", "Basque", "Bateke", "Belorussian", "Belorussian", "Bislama",
    "Bosnian", "Breton", "Bubi", "Bulgarian", "Cantonese", "Castilian",
    "Castilian Spanish", "Catalan", "Catalán", "Chinese", "Corsican",
    "Creole", "Criolo", "Criuolo", "Croatian", "Czech", "Danish", "Dari",
    "Dhivehi", "Dutch", "Dzongkha", "English", "Eschira", "Estonian",
    "Faeroese", "Fang", "Farsi", "Fijian", "Filipino", "Finnish", "Flemish",
    "Fon", "French", "French patois", "Galole", "Garifuna (Carib)",
    "Georgian", "German", "Greek", "Greenlandic", "Guaragigna",
    "Hansa, Swahili", "Hassaniya Arabic", "Hazaragi", "Hebrew", "Hindi",
    "Hindustani", "Hungarian", "Ibo", "Icelandic", "Irish Gaelic", "Italian",
    "Japanese", "Kazak", "Kekchi", "Kemak", "Khmer", "Kikongo", "Kingwana",
    "Kinyarwanda", "Kirundi", "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin",
    "Latvian", "Leboa", "Lingala", "Luxermbourgish", "Macedonian", "Magyar",
    "Malagasy", "Malay", "Maltese", "Mambae", "Mandarin", "Mandingo",
    "Marshallese", "Mayan", "Melanesian", "Moldovan", "Mongolian",
    "Monokutuba", "Myene", "Nahua", "Nauruan", "Nepalese dialects", "Nepali",
    "Orominga", "Palau", "Pashtu", "Patois", "Polish", "Portuguese",
    "Provençal", "Punjabi", "Quechua", "Rohingya", "Romanian", "Russian",
    "Samoan", "Sangho", "Serbian", "Setswana", "Shikomoro", "Sinhala",
    "Slovak", "Slovene", "Slovenian", "Somali", "Spanish", "Swahili",
    "Swedish", "Tagalog", "Tajik", "Tetum", "Thai", "The Myanmar language",
    "Tibetan", "Tigre and Kunama", "Tigrigna", "Tongan", "Tshiluba",
    "Turkish", "Turkmen", "Tuvaluan", "Ukrainian", "Urdu", "Uzbek",
    "Vietnamese", "Yoruba"
];
const PreprintForm = () => {
    const { publication, updateData, textValidation, setTextValidation, step } = useNewPublicationContext();
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [disableAllow, setDisableAllow] = useState(false)
    const valid = step === 1;

    useEffect(() => {
        if (Object.keys(publication.data).length === 0) {
            const initialArticle = {
                title: '',
                journalName: '',
                volume: '',
                issue: '',
                pages: '',
                doi: '',
                linkOfThePaper: '',
                comment: '',
                indexing: '',
                language: '',
                references: '',
                year: '',
                haveArticle: '',
                hasDoi: true,
                allowComment: true, // Default to true
                generateDoi: false
            };
            if (initialArticle.haveArticle.trim() !== "") {
                initialArticle.allowComment = false;
            }

            updateData(initialArticle);
        }
    }, []);
    const handleIndexChange = (value) => {
        updateData({ ...publication.data, "indexing": value });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        // Handle the main state update
        let updatedData = { ...publication.data, [name]: value };

        // Additional logic for 'haveArticle' field
        if (name === "haveArticle") {

            if (value.trim() !== "") {
                console.log("haveArticle is non-empty, setting allowComment to false.");
                updatedData = { ...updatedData, allowComment: false };  // Set allowComment to false
                setDisableAllow(true)
            } else {
                // If haveArticle is empty, allowComment can be true (or whatever your logic requires)
                updatedData = { ...updatedData, allowComment: true };
                setDisableAllow(false)
            }
        }
        const newDebounceTimer = setTimeout(() => {
            updateData(updatedData);
        }, 10);
        // Store the new debounce timer ID
        setDebounceTimer(newDebounceTimer);

    };



    const currentYear = new Date().getFullYear();

    return (
        <Paper sx={{ opacity: valid ? 1 : 0.5 }}>
            <Stack p={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <Schema sx={{ width: 60, height: 60, color: "primary.main" }} />
                            <Typography color="primary.main" variant="h4">
                                <b>{publication.type}</b>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TitleValidationTextField
                            title={publication.data.title}
                            setTitle={handleChange}
                            isValid={textValidation}
                            setIsValid={setTextValidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            label="Journal Name (Optional)"
                            name="journalName"
                            value={publication.data.journalName}
                            onChange={handleChange}
                            inputProps={{ maxLength: 255 }}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            label="Volume (Optional)"
                            name="volume"
                            value={publication.data.volume}
                            onChange={handleChange}
                            inputProps={{ maxLength: 50 }}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            label="Issue (Optional)"
                            name="issue"
                            value={publication.data.issue}
                            onChange={handleChange}
                            inputProps={{ maxLength: 50 }}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            label="Pages"
                            name="pages"
                            value={publication.data.pages}
                            onChange={handleChange}
                            inputProps={{ maxLength: 50 }}

                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            size='small'
                            disabled={!valid}
                            fullWidth
                            type="number"
                            label="Year"
                            name="year"
                            value={publication.data.year}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            label="Link of the Paper (Optional)"
                            name="linkOfThePaper"
                            value={publication.data.linkOfThePaper}
                            onChange={handleChange}
                            inputProps={{ maxLength: 500 }}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel>Language</InputLabel>
                            <Select
                                label="Language"
                                name='language'
                                value={publication.data.language}
                                onChange={handleChange}
                            >
                                {languages.map((langue, index) => (
                                    <MenuItem key={index} value={langue}>
                                        {langue}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <IndexSelector onIndexChange={handleIndexChange} initialIndex={publication.data.indexing} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack border="1px solid" borderRadius={3} p={2} borderColor="primary.main">
                            <DoiGenerate generateDoi={publication.data.generateDoi} doi={publication.data.doi} hasDoi={publication.data.hasDoi} setHasDoi={handleChange} handleChange={handleChange} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack border="1px solid" borderRadius={3} p={2} borderColor="primary.main">
                            <Typography color="primary.main">Have you published an article from this preprint?</Typography>

                            <MyPublicationSelect
                                selectedPublication={publication.data.haveArticle}
                                setSelectedPublication={(newValue) => {
                                    // Directly set the selected publication's header value in the form data
                                    handleChange({
                                        target: {
                                            name: "haveArticle",
                                            value: newValue.header, // Handle null or undefined
                                        }
                                    });
                                }}
                            />

                        </Stack>

                    </Grid>

                    <Grid item xs={12}>
                        <Stack border="1px solid" borderRadius={3} p={2} borderColor="primary.main">
                            <Typography color="primary.main" sx={{ mb: 2 }}>
                                Do you allow other researchers to add comments and review your preprint?
                            </Typography>
                            <RadioGroup
                                name="allowComment"
                                row
                                value={String(publication.data.allowComment)} // Convert boolean to string
                                onChange={handleChange} // Updated function is used here
                            >
                                <FormControlLabel
                                    name="allowComment"
                                    value="true"
                                    control={<Radio disabled={disableAllow} color="primary" />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    name="allowComment"
                                    value="false"
                                    control={<Radio color="primary" />}
                                    label="No"
                                />
                            </RadioGroup>
                        </Stack>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            multiline
                            rows={3}
                            label="Abstract"
                            name="comment"
                            value={publication.data.comment}
                            onChange={handleChange}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={!valid}
                            size="small"
                            fullWidth
                            multiline
                            rows={10}
                            label="References (Optional) "
                            name="references"
                            value={publication.data.references}
                            onChange={handleChange}
                        />
                        <Typography variant='body2' color="gray">By adding your references, you can increase your discoverability in the searches of other researchers</Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Paper>
    );
};

export default PreprintForm;
