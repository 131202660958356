import {
    BarChart,
} from '@mui/x-charts';
import {
    Paper,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
    CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllPublicationsByYear } from '../../services/publicationService';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';

const BarChartBox = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Renk paletini burada oluşturuyoruz
    const publicationTypeColors = {
        ARTICLE: "#4caf50", // Yeşil
        THESIS: "#2196f3", // Mavi
        CHAPTER_IN_A_BOOK: "#ff9800", // Turuncu
        CONFERENCE_PAPER: "#9c27b0", // Mor
        RESEARCH_PROJECT: "#3f51b5", // Lacivert
        COMPANY_TEST_REPORT: "#f44336", // Kırmızı
        BOOK: "#795548", // Kahverengi
        LETTER: "#00bcd4", // Turkuaz
        RETRACTED_ARTICLE: "#e91e63", // Pembe
        EDITORIAL_MATERIAL: "#607d8b", // Gri
        PREPRINT: "#673ab7", // Koyu Mor
        UNKNOWN: "#9e9e9e", // Varsayılan Gri
    };

    const [publicationsByYear, setPublicationsByYear] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userId } = useParams();
    const { token } = useUserContext();
    const formatLabel = (label) => {
        return label
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    useEffect(() => {
        const fetchPublications = async () => {
            try {
                const data = await getAllPublicationsByYear(token, userId);
                setPublicationsByYear(data || {});
                console.log(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPublications();
    }, [userId, token]);

    if (loading) {
        return (
            <Paper sx={{ borderRadius: 3, p: 2, overflowX: 'auto' }}>
                <Stack alignItems="center" justifyContent="center" height={isMobile ? 200 : 300}>
                    <CircularProgress size={60} thickness={4} />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Loading Publications Data...
                    </Typography>
                </Stack>
            </Paper>
        );
    }

    if (error) return <div>Error: {error}</div>;

    const publicationsArray = Object.values(publicationsByYear).flat();
    const years = [...new Set(publicationsArray.map(pub => parseInt(pub.year, 10)))].sort();
    const publicationTypes = [...new Set(publicationsArray.map(pub => pub.type))];

    const seriesData = publicationTypes.map(type => ({
        data: years.map(year => {
            return publicationsArray.filter(pub => pub.year === year && pub.type === type).length;
        }),
        label: type,
        color: publicationTypeColors[type] || publicationTypeColors.UNKNOWN, // Renk paletinden al
    }));

    return (
        <Paper sx={{ borderRadius: 3, p: 2, overflowX: 'auto' }}>
            <Stack spacing={2} sx={{ overflowX: "scroll" }}>
                <Typography variant="h6">Publications by Year</Typography>
                <BarChart
                    xAxis={[{
                        scaleType: 'band',
                        data: years.map(year => year.toString()),
                        dataKey: 'year',
                    }]}
                    series={seriesData.map(series => ({
                        data: series.data,
                        label: formatLabel(series.label),
                        color: series.color,
                    }))}
                    width={isMobile ? 300 : 900}
                    height={isMobile ? 200 : 300}
                    tooltip={{
                        labelFormatter: (value, index) => {
                            const type = formatLabel(publicationTypes[index]);
                            return `Type: ${type}, Publications: ${value}`;
                        },
                    }}
                />
            </Stack>
        </Paper>
    );
};

export default BarChartBox;
