import React, { useState, useEffect } from 'react';
import {
  Paper,
  Stack,
  Typography,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  Folder,
  Edit,
  ArrowUpward,
  ArrowDownward,
  Delete,
  Add,
} from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../../../auth/AuthProvider';
import RawDataTable from '../RawDataTable';
import CreateFolder from './CreateFolder';
import {
  getFolderAll,
  moveFolderUp,
  moveFolderDown,
  deleteFolder,
  updateFolderName,
} from '../../../services/editRawDataService';
import ViewExample from '../../newPublication/rawdataForm/ViewExample';

function RawDataInfoEdit() {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { publicationId } = useParams();
  const { token } = useUserContext();
  const [isCreateFolder, setIsCreateFolder] = useState(false);
  const [deletingFolderId, setDeletingFolderId] = useState(null);
  const [editingFolderId, setEditingFolderId] = useState(null);
  const [folderName, setFolderName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const fetchFolderAll = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getFolderAll(publicationId, token);
      setFolders(response);
    } catch (err) {
      setError('Failed to fetch folders. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFolderAll();
  }, [publicationId, token]);

  const handleAddFolder = (newFolder) => {
    setFolders((prev) => [...prev, newFolder]);
  };

  const handleMove = async (folderId, direction) => {
    const moveFunc = direction === 'up' ? moveFolderUp : moveFolderDown;
    try {
      await moveFunc(folderId, token);
      setFolders((prev) => {
        const folderIndex = prev.findIndex((folder) => folder.id === folderId);
        if (
          (direction === 'up' && folderIndex > 0) ||
          (direction === 'down' && folderIndex < prev.length - 1)
        ) {
          const updatedFolders = [...prev];
          const swapIndex =
            direction === 'up' ? folderIndex - 1 : folderIndex + 1;
          [updatedFolders[folderIndex], updatedFolders[swapIndex]] = [
            updatedFolders[swapIndex],
            updatedFolders[folderIndex],
          ];
          return updatedFolders;
        }
        return prev;
      });
    } catch (err) {
      setError(`Failed to move folder ${direction}. Please try again later.`);
      console.error(err);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    setDeletingFolderId(folderId);
    try {
      await deleteFolder(folderId, token);
      setFolders((prev) => prev.filter((folder) => folder.id !== folderId));
    } catch (err) {
      setError('Failed to delete folder. Please try again later.');
      console.error(err);
    } finally {
      setDeletingFolderId(null);
    }
  };

  const handleEditFolderName = (folder) => {
    setEditingFolderId(folder.id);
    setFolderName(folder.name);
  };

  const handleUpdateFolderName = async (folderId) => {
    try {
      await updateFolderName(folderId, token, folderName);
      setFolders((prev) =>
        prev.map((folder) =>
          folder.id === folderId ? { ...folder, name: folderName } : folder
        )
      );
      setEditingFolderId(null);
      setFolderName('');
    } catch (err) {
      setError('Failed to update folder name. Please try again later.');
      console.error(err);
    }
  };

  const handleCancelEdit = () => {
    setEditingFolderId(null);
    setFolderName('');
  };

  const handleConfirmDelete = () => {
    if (deletingFolderId) {
      handleDeleteFolder(deletingFolderId);
      setOpenDialog(false);
    }
  };

  return (
    <Paper elevation={1}>
      <Stack spacing={2} p={1}>
        <ViewExample handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} setOpen={setOpen} />
        {loading && <CircularProgress />}
        {folders?.length == 0 && <Paper sx={{ borderRadius: 3 }}>
          <Stack p={2} spacing={1}>
            <Typography color="primary.main" variant='h5' fontWeight="bold">Upload Raw Data</Typography>
            <Typography variant='h6'>
              Firstly, to maintain your work in an organized and systematic manner, it is essential to create different folders within your project scope.
              Each folder should cover a specific area or file type of your project, allowing you to collect and store your data under specific headings.
              This method makes your data more easily accessible and provides order.
            </Typography>
            <Typography variant='h6'>
              Raw data can be uploaded in different formats (e.g., Excel, Sap 2000, Matlab, Java, Abaqus, SPSS files ext.). <b>If it is a questionary survey</b>, upload all the forms as a merged pdf file.
              If it is a software modelling upload it as a zip file.
            </Typography>
            <Typography variant='h6'>
              Preview images for raw data are needed to be uploaded by authors.
              It would be useful to upload an image that gives an idea of what other researchers will have if the corresponding data or model are purchased. The images to be uploaded can sometimes be a graphic image, sometimes an image of software code on a computer, and sometimes an image showing a single page of an entire survey.
              For questionary survey, upload the first page of the survey form as a jpg image : <b><Link onClick={handleClickOpen}>view example</Link></b>
            </Typography>
          </Stack>
        </Paper>}
        {folders.map((folder) => (
          <Paper elevation={5} sx={{ p: 1 }} key={folder.id}>
            <Stack>
              <Stack p={1}>
                <Stack p={1} alignItems="center" direction="row" spacing={0.5}>
                  <Folder color="primary" />
                  {editingFolderId === folder.id ? (
                    <>
                      <TextField
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                        size="small"
                      />
                      <Button onClick={() => handleUpdateFolderName(folder.id)}>Save</Button>
                      <Button onClick={handleCancelEdit}>Cancel</Button>
                    </>
                  ) : (
                    <Stack direction="row" alignItems="center" width="100%">
                      <Typography color="primary" variant='h6'>{folder.name}</Typography>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ ml: 'auto' }}>
                        <Stack direction="row" spacing={1}>
                          <IconButton onClick={() => handleMove(folder.id, 'up')} disabled={folders.indexOf(folder) === 0} color="primary">
                            <ArrowUpward />
                          </IconButton>
                          <IconButton onClick={() => handleMove(folder.id, 'down')} disabled={folders.indexOf(folder) === folders.length - 1} color="primary">
                            <ArrowDownward />
                          </IconButton>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Button
                            onClick={() => handleEditFolderName(folder)}
                            startIcon={<Edit />}
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: 2, padding: '6px 12px', '&:hover': { backgroundColor: 'rgba(25, 118, 210, 0.1)' } }}
                          >
                            Edit Name
                          </Button>
                          <Button
                            onClick={() => {
                              setDeletingFolderId(folder.id);
                              setOpenDialog(true);
                            }}
                            disabled={deletingFolderId === folder.id}
                            startIcon={<Delete />}
                            variant="outlined"
                            color="error"
                            sx={{ borderRadius: 2, padding: '6px 12px', '&:hover': { backgroundColor: 'rgba(255, 82, 82, 0.1)' } }}
                          >
                            {deletingFolderId === folder.id ? <CircularProgress size={24} /> : 'Delete Raw Data Type'}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
                <RawDataTable edit={true} rawData={folder.rawData} folderId={folder.id} />


              </Stack>
            </Stack>


          </Paper>
        ))}
        {!isCreateFolder && (
          <Button startIcon={<Add />} variant='outlined' onClick={() => setIsCreateFolder(true)}>
            {folders.length > 0
              ? "Upload another raw data variable for the same sample or study."
              : "Upload raw data"}
          </Button>
        )}
        {isCreateFolder && <CreateFolder onAddFolder={handleAddFolder} onCancel={() => setIsCreateFolder(false)} />}
      </Stack>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this folder?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default RawDataInfoEdit;
