import { Paper, Stack, useMediaQuery, Box } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';

const baseUrl = process.env.REACT_APP_BASE_URL;

const PieChartBox = ({ publicStatus = false }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [data, setData] = useState([]);
    const { userId = 1 } = useParams(); // Default userId if not provided
    const { token } = useUserContext();

    // Renk paleti
    const publicationTypeColors = {
        ARTICLE: "#4caf50", // Yeşil
        THESIS: "#2196f3", // Mavi
        CHAPTER_IN_A_BOOK: "#ff9800", // Turuncu
        CONFERENCE_PAPER: "#9c27b0", // Mor
        RESEARCH_PROJECT: "#3f51b5", // Lacivert
        COMPANY_TEST_REPORT: "#f44336", // Kırmızı
        BOOK: "#795548", // Kahverengi
        LETTER: "#00bcd4", // Turkuaz
        RETRACTED_ARTICLE: "#e91e63", // Pembe
        EDITORIAL_MATERIAL: "#607d8b", // Gri
        PREPRINT: "#673ab7", // Koyu Mor
        UNKNOWN: "#9e9e9e", // Varsayılan Gri
    };

    const fetchData = async (url, isAuthRequired = false) => {
        try {
            const config = isAuthRequired
                ? { headers: { Authorization: `Bearer ${token}` } }
                : {};
            const response = await axios.get(url, config);
            const formattedData = [
                { id: 0, value: response.data.articleCount || 0, label: "Article", color: publicationTypeColors.ARTICLE },
                { id: 1, value: response.data.thesisCount || 0, label: "Thesis", color: publicationTypeColors.THESIS },
                { id: 2, value: response.data.chapterInABook || 0, label: "Chapter in a book", color: publicationTypeColors.CHAPTER_IN_A_BOOK },
                { id: 3, value: response.data.conferencePaperCount || 0, label: "Conference paper", color: publicationTypeColors.CONFERENCE_PAPER },
                { id: 4, value: response.data.researchProjectCount || 0, label: "Research project", color: publicationTypeColors.RESEARCH_PROJECT },
                { id: 5, value: response.data.companyTestReportCount || 0, label: "Company test report", color: publicationTypeColors.COMPANY_TEST_REPORT },
                { id: 7, value: response.data.letterCount || 0, label: "Letter", color: publicationTypeColors.LETTER },
                { id: 8, value: response.data.editorialMaterialCount || 0, label: "Editorial material", color: publicationTypeColors.EDITORIAL_MATERIAL },
                { id: 9, value: response.data.retractedArticleCount || 0, label: "Retracted article", color: publicationTypeColors.RETRACTED_ARTICLE },
                { id: 9, value: response.data.preprintCount || 0, label: "Preprint", color: publicationTypeColors.PREPRINT },
                ...(response.data.bookCount
                    ? [{ id: 6, value: response.data.bookCount, label: "Book", color: publicationTypeColors.BOOK }]
                    : []),
            ];
            setData(formattedData);
        } catch (error) {
            console.error("Error fetching publication counts:", error);
        }
    };

    useEffect(() => {
        const url = token
            ? `${baseUrl}/api/v1/user/${userId}/publication-counts`
            : `${baseUrl}/api/v1/no-auth/user/${userId}/publication-counts`;

        fetchData(url, token); // Fetch with auth if not public
    }, [userId, publicStatus, token]);

    const pieChartData = [
        {
            data,
            outerRadius: 100,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            cx: 110,
            label: false,
        },
    ];

    return (
        <Paper sx={{ borderRadius: 3, p: 2 }}>
            <Stack direction="column" spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: isMobile ? '100%' : '400px',
                    }}
                >
                    <PieChart
                        series={pieChartData}
                        height={200}
                        width={isMobile ? 200 : 300}
                        slotProps={{
                            legend: {
                                position: 'bottom', // Move legend below the chart
                                hidden: true, // Hide legend on mobile
                                layout: 'horizontal', // Align legend horizontally
                            },
                        }}
                    />
                </Box>
            </Stack>
        </Paper>
    );
};

export default PieChartBox;
