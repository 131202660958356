import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Card, CardActions, CardContent, ListItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../auth/AuthProvider';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ScholarListItem = ({ item, handleAddGoogle }) => {
    const [loading, setLoading] = useState(false);
    const [isTitleUnique, setIsTitleUnique] = useState(null); // Track uniqueness of the title
    const { token } = useUserContext();

    // Check title uniqueness when the component mounts or the title changes
    useEffect(() => {
        let isMounted = true; // To avoid state updates on unmounted components

        const checkTitleUniqueness = async (title) => {
            if (!title) return; // Skip API call if title is missing
            setLoading(true);
            try {
                const response = await axios.post(
                    `${baseUrl}/api/v1/publication/check-title`,
                    { title },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (isMounted) {
                    setIsTitleUnique(response.data.isUnique); // Update state only if component is mounted
                }
            } catch (error) {
                console.error('Error checking title uniqueness:', error);
                if (isMounted) {
                    setIsTitleUnique(false); // Default to not unique on error
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        checkTitleUniqueness(item?.title);

        return () => {
            isMounted = false; // Cleanup flag to prevent memory leaks
        };
    }, [item?.title, token]);

    return (
        <ListItem>
            <Card sx={{ width: '100%', bgcolor: 'background.paper', boxShadow: 3 }}>
                <CardContent>
                    <Typography
                        color="primary"
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {item?.title || 'Untitled Publication'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {item?.authors || 'Unknown Authors'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {item?.journalInfo || 'No Journal Information'}
                    </Typography>
                </CardContent>
                <CardActions>
                    <LoadingButton
                        disabled={loading || isTitleUnique === null || !isTitleUnique}
                        loading={loading}
                        startIcon={<Add />}
                        onClick={() => handleAddGoogle(item?.link)}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            padding: '6px 16px',
                        }}
                    >
                        Add
                    </LoadingButton>
                </CardActions>
            </Card>
        </ListItem>
    );
};

export default ScholarListItem;
