import { Add } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, ListItem, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../auth/AuthProvider';

const baseUrl = process.env.REACT_APP_BASE_URL;
const ScopusItemList = ({ item, handleAutoAdd }) => {
    const [loading, setLoading] = useState(false);
    const [isTitleUnique, setIsTitleUnique] = useState(null);
    const { token, user } = useUserContext();

    // Check title uniqueness
    useEffect(() => {
        const checkTitleUniqueness = async (value) => {
            try {
                setLoading(true);
                const response = await axios.post(`${baseUrl}/api/v1/publication/check-title`, { title: value }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setIsTitleUnique(response.data.isUnique); // The API should return `isUnique`
            } catch (error) {
                console.error("Error checking title uniqueness:", error);
                setIsTitleUnique(false); // In case of an error, consider title not unique
            } finally {
                setLoading(false);
            }
        };

        // Only check title uniqueness if the title exists
        if (item["dc:title"]) {
            checkTitleUniqueness(item["dc:title"]);
        }
    }, [item["dc:title"], token]);

    return (
        <ListItem sx={{ marginBottom: 2 }}>
            <Card sx={{ width: '100%', bgcolor: 'background.paper', boxShadow: 3 }}>
                <CardContent>
                    <Typography
                        color="primary"
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {item["dc:title"] || 'No Title Available'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 1 }}
                    >
                        <strong>Author:</strong> {item["dc:creator"] || 'Unknown Author'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 0.5 }}
                    >
                        <strong>Publication:</strong>{' '}
                        {item["prism:publicationName"] || 'No Publication Name'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 0.5 }}
                    >
                        <strong>Publication Type:</strong>{' '}
                        {item["subtypeDescription"] || 'Type'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 0.5 }}
                    >
                        <strong>DOI:</strong> {item["prism:doi"] || 'No DOI Available'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 0.5 }}
                    >
                        <strong>Published in:</strong>{' '}
                        {item["prism:coverDisplayDate"] || 'No Date Available'}
                    </Typography>
                    <Divider sx={{ marginTop: 1 }} />
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: 1 }}
                    >
                        <strong>Affiliation:</strong>{' '}
                        {item.affiliation?.[0]?.affilname || 'No Affiliation Available'}{' '}
                        - {item.affiliation?.[0]?.["affiliation-city"] || 'Unknown City'},{' '}
                        {item.affiliation?.[0]?.["affiliation-country"] || 'Unknown Country'}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        <Button
                            disabled={loading || isTitleUnique === null || !isTitleUnique}
                            variant="contained"
                            color="primary"
                            onClick={() => handleAutoAdd(item)}
                            sx={{
                                textTransform: 'none',
                                borderRadius: '8px',
                                padding: '6px 16px',
                                boxShadow: 2,
                            }}
                        >
                            ADD
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </ListItem>
    )
}

export default ScopusItemList
