import { Box, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useUserContext } from '../auth/AuthProvider';
const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

function PayPage() {
    const [payToken, setPayToken] = useState(null);
    const {token} = useUserContext()
    const [loading, setLoading] = useState(true);

    // Token alım fonksiyonu
    const fetchToken = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/v1/paytr/`, {
                method: 'GET', // Eğer GET dışında bir yöntemse değiştirin (ör. POST)
                headers: {
                    'Content-Type': 'application/json', // İçerik tipi
                    'Authorization': `Bearer ${token}` // Token'ı Authorization başlığına ekleme
                }
            }); // API'nizin URL'sini buraya yerleştirin
            const data = await response.json();

            // JSON stringi doğru şekilde parse et
            const parsedResponse = JSON.parse(data.response);

            if (parsedResponse.status === 'success') {
                setPayToken(parsedResponse.token);
            } else {
                console.error("Error: ", parsedResponse.reason);
                alert("Hata: " + parsedResponse.reason);
            }
        } catch (error) {
            console.error("Network error:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchToken();
    }, []);

    return (
        <>
            {loading ? (
                <p>Yükleniyor...</p>
            ) : (
                token && (
                    <Box 
                        height="100vh"
                        sx={{
                            width: '100%',          // İstediğiniz genişlik burada ayarlanabilir
                            maxWidth: '1200px',     // Genişliğin bir sınırı olması için
                            margin: '0 auto',       // Merkezi konumlandırma
                            overflow: 'hidden',     // Taşmaların gizlenmesi
                        }}
                    >
                        <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
                        <iframe
                            src={`https://www.paytr.com/odeme/guvenli/${payToken}`}
                            id="paytriframe"
                            frameBorder="0"
                            scrolling="no"
                            style={{ width: '100%', height: '100%' }}
                        ></iframe>
                        <script>
                            iFrameResize({ }, '#paytriframe');
                        </script>
                    </Box>
                )
            )}
        </>
    );
}

export default PayPage;
