import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL
export const getCommentPage = async (token, preprintId, page = 0, size = 10) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/comments/preprints/${preprintId}?page=${page}&size=${size}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const getCommentDraft = async (token,page = 0, size = 10) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/comments/preprints/draft-save-as?page=${page}&size=${size}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const getCommentPublish = async (token,page = 0, size = 10) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/comments/preprints/publish?page=${page}&size=${size}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const getSummries = async (token, preprintId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/comments/preprints/${preprintId}/summaries`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const getComment = async (token, commentId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/comments/${commentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const updateComment = async (token, commentId, formData) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/comments/${commentId}`,formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const deleteComment = async (token, commentId) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/v1/comments/preprints/${commentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching comment page:', error);
        throw error;
    }
}
export const saveComment = async (token, preprintId, formData) => {
    try {
        const response = await axios.post(`${baseUrl}/api/v1/comments/preprints/${preprintId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching home page publications:', error);
        throw error;
    }
}