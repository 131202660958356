import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Container, styled, tableCellClasses, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASE_URL;

const Preprint2025 = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0); // 0-based index for pagination
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default size
    const [totalItems, setTotalItems] = useState(0); // Total number of items in backend (used for pagination)

    useEffect(() => {
        // Fetch data when the page or rows per page changes
        axios
            .get(`${baseUrl}/api/v1/no-auth/publication/preprint-2025?page=${page + 1}&size=${rowsPerPage}`) // API call with page and size
            .then((response) => {
             
                setData(response.data.content); // Update data from response (content array)
                setTotalItems(response.data.totalElements); // Total elements for pagination
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [page, rowsPerPage]); // Triggered when `page` or `rowsPerPage` changes

    const handleChangePage = (event, newPage) => {
        setPage(newPage); // Update page
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
        setPage(0); // Reset to first page
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (
        <Container sx={{ mt: 2 }}>
            <Typography p={2} variant='h5' color="primary.main">Assigned recent dois for preprints</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>DOI</StyledTableCell>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell>Author(s)</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>{item.doi}</StyledTableCell>
                                <StyledTableCell><Link
                                    to={`/publication/${encodeURIComponent(item.articleName + " " + item.articleId).replace(/-/g, '_').replace(/%20/g, '-')}`} // Encoding and replacing
                                    style={{

                                        color: 'inherit'
                                    }}
                                >{item.articleName}</Link></StyledTableCell>
                                <Link to={`/researcher/${item.authorId}`}>
                                    <StyledTableCell>{item.authorFullName}</StyledTableCell>
                                </Link>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={totalItems} // Total number of items from the backend
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]} // Options for rows per page
            />
        </Container>
    );
};

export default Preprint2025;
